import { Injectable } from '@angular/core';
import { AppInsights } from 'applicationinsights-js'
import { AppConfig } from 'src/app/app.config.service';

@Injectable({
  providedIn: 'root'
})
export class AppInsightsService {
  private config: Microsoft.ApplicationInsights.IConfig={
    instrumentationKey: AppConfig.configurations.appInsights.instrumentationKey
  }
  constructor() {
    if(!AppInsights.config){
      AppInsights.downloadAndSetup(this.config);
    }
   }

   logPageView(name : string, url?: string, properties?: any, measurements?: any, duration?: number) { // option to call manually
    AppInsights.trackPageView(name, url, properties, measurements, duration);
  }

  logEvent(name : string, properties?: any, measurements?: any) {
    AppInsights.trackEvent(name, properties, measurements);
  }

  logMetric(name: string, average: number,
    sampleCount?: number, min?: number, max?: number, 
    properties?: { [key: string]: any }) {
    AppInsights.trackMetric(name, average, sampleCount, min, max, properties)
  }

  logException(exception: Error, handledAt?: string, properties?: { [name: string]: string },
    measurements?: { [name: string]: number },severityLevel?: number) {
    AppInsights.trackException(exception, handledAt, properties, measurements, severityLevel)
  }

  logTrace(message: string, properties?: { [name: string]: string }, severityLevel?: number) {
    AppInsights.trackTrace(message, properties, severityLevel);
  }
}
