import { Injectable } from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse, HttpEvent, HttpResponse } from '@angular/common/http';
import { switchMap, first, catchError, finalize, map, tap, take } from 'rxjs/operators';
import { AuthService } from './auth/auth.service';
import { throwError } from 'rxjs';
import { SpinnerService } from './common/loading/loading.service';
import { AppConfig } from './app.config.service';
import { AppInsightsService } from './services/error-handling/app-insights.service';
import { ShareDataService } from './services/share-data.service';
import { base_customer_profile, customer_profile } from './shared/models/customer-profile';

@Injectable()
export class ApolloInterceptor implements HttpInterceptor {
    constructor(
        private auth: AuthService,
        private spinnerService: SpinnerService,
        private appInsightsService: AppInsightsService,
        private data: ShareDataService) {
    }
    customerprofile: base_customer_profile = new customer_profile;
    sub: any;
    token: any;
    intercept(req: HttpRequest<any>, next: HttpHandler) {
        // Filter your endpoint in order to only edit the graphql-related requests
        const url = req.url.indexOf('api');
        if (url > -1) {
            this.spinnerService.show();
            // Get your jwt from your usual source, can be a facade, a service, or even sync data like localStorage
            this.sub = this.data.getCustomerProfile().subscribe(
                (data) => {
                    if (data) {
                        this.customerprofile = data;
                    }
                }
            );
            this.sub = this.data.getToken().subscribe(
                (data) => {
                    if (data) {
                        this.token = data;
                    }
                }
            );

            if (this.token) {
                let header = req.clone({
                    setHeaders: {
                        Accept: 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'GraphQL-Version': '3',
                        Authorization: `Bearer ${this.token}`
                    }
                })
                return next.handle(header).pipe(
                    tap((event: HttpEvent<any>) => {
                        if (event instanceof HttpResponse && event.ok === true) {
                            this.appInsightsService.logPageView(req.url, req.urlWithParams, event);
                        }
                    }),
                    finalize(() => {
                        this.spinnerService.hide();
                    })
                );
            } else {
                return this.auth.getTokenSilently$()
                    .pipe(
                        // Make sure the request won't replay when your token gets updated
                        take(1),
                        switchMap(token => {
                            // Simply add the Authorization header to the request
                            const clone = req.clone({
                                setHeaders: {
                                    Accept: 'application/json',
                                    'Access-Control-Allow-Origin': '*',
                                    'GraphQL-Version': '3',
                                    Authorization: `Bearer ${token}`
                                }
                            });
                            // And you're done !
                            return next.handle(clone).pipe(
                                tap((event: HttpEvent<any>) => {
                                    if (event instanceof HttpResponse && event.ok === true) {
                                        this.appInsightsService.logPageView(req.url, req.urlWithParams, event);
                                    }
                                }),
                                finalize(() => {
                                    this.spinnerService.hide();
                                })

                            );
                        })
                    );
            }
        }
        // If it's not a graphql request, just give it to the next handler.
        return next.handle(req).pipe(
            tap((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse && event.ok === true) {
                    this.appInsightsService.logPageView(req.url, req.urlWithParams, event);
                }
            }),
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401) {
                    // refresh token
                } else if (error.status === 424) {
                    return throwError(error.error.detail);
                } else {
                    return throwError(error);
                }
            })
        );
    }
}