import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { clinicAddress, clinicCheckDtp, clinicHeader, clinicInfo, clinics, clinicCheckEnrolledInAutoPay } from '../queries/clinic-queries';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { GraphQLModule } from 'src/app/graphql.module';

@Injectable({
  providedIn: 'root'
})
export class ClinicService {

  constructor(private apollo: Apollo) { }

  public getClinics(args): Observable<any> {
    return this.apollo.query<any>({
      query: clinics,
      variables: {
        first: args.first,
        last: args.last,
        after: args.after,
        before: args.before,
        where: args.filter ? args.filter : null,
        order: args.orderBy
      }
    }).pipe(map(({ data }) => data.clinics));
  }

  public getClinicInfo(args): Observable<any> {
    return this.apollo.query<any>({
      query: clinicInfo,
      variables: {
        where: args.filter ? args.filter : null
      }
    }).pipe(map(({ data }) => data.clinics.nodes[0]));
  }

  public getHeaderClinics(args): Observable<any> {
    return this.apollo.query<any>({
      query: clinicHeader
    }).pipe(map(({ data }) => data.clinics));
  }

  public getClinicCheckDtp(clinicId): Observable<any> {
    return this.apollo.query<any>({
      query: clinicCheckDtp,
      variables: {
        where: { id: { eq: clinicId } }
      }
    }).pipe(map(({ data }) => data.clinics.nodes[0]));
  }

  public getClinicAddress(args): Observable<any> {
    return this.apollo.query<any>({
      query: clinicAddress,
      variables: {
        where: args.filter ? args.filter : null
      }
    }).pipe(map(({ data }) => data.clinics.nodes[0]));
  }
  public getClinicCheckEnrolledInAutoPay(clinicId): Observable<any> {
    return this.apollo.query<any>({
      query: clinicCheckEnrolledInAutoPay,
      variables: {
        where: { id: { eq: clinicId } }
      }
    }).pipe(map(({ data }) => data.clinics.nodes[0]));
  }
}
