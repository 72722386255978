<!-- Footer -->
<footer class="page-footer">
  <div class="container-fluid text-center text-md-left">
    <div class="row">
      <div class="col-xl-5 col-lg-12">
        <div class="row">
          <div class="col-xl-6 col-lg-6 col-md-5">
            <div class="footer-logo">
              <img src="./../../../assets/img/biote-white.png" alt="Biote Medical">
            </div>
            <div class="footer-address">
              <div class="address-icon">
                <i class="fa fa-map-marker"></i>
              </div>
              <div class="address-text">
                1875 W. Walnut Hill Ln.<br />Suite 100, Irving, TX 75038
              </div>
            </div>
          </div>

          <div class="col-xl-6 col-lg-6 col-md-7">
            <div class="footer-contact">
              <div class="fbc-phone">
                <a mat-flat-button color="green" href="tel:8446041246" target="_blank">
                  <i class="fa fa-phone"></i>&nbsp;(844) 604-1246
                </a>
              </div>
              <div class="fbc-block">
                <i class="fa fa-file-o"></i> Fax (888) 496-0394
              </div>
              <div class="fbc-block">
                <i class="fa fa-clock-o"></i> Monday-Friday, 8:00am-5:00pm
              </div>
              <div class="fbc-block">
                <i class="fa fa-envelope-o"></i> <a target="_blank" href="mailto:support@Biote.com">support@Biote.com</a>
              </div>
              <div class="fbc-block">
                <i class="fa fa-globe"></i> <a href="https://www.biote.com/" target="_blank">https://biote.com/</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="w-100 d-xl-none footer-seperator"></div>

      <div class="col-xl-4 col-lg-12">
        <mat-accordion multi>
          <div class="row">
            <div class="col-xl-6 col-lg-6">
              <div class="footer-links">
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      INFORMATION
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div>
                    <ul>
                      <li><a [routerLink]="['/info/shipping-returns']">Shipping & Returns</a></li>
                      <li><a href="https://biote.com/privacy-policy" target="_blank">Privacy Policy</a></li>
                      <li><a href="https://biote.com/terms-of-use"  target="_blank">Terms of Use</a></li>
                      <li><a [routerLink]="['/info/shipping-policy']">Shipping Policy</a></li>
                    </ul>
                  </div>
                </mat-expansion-panel>
              </div>
            </div>

            <div class="col-xl-6 col-lg-6">
              <div class="footer-links">
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      SUPPORT
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div>
                    <ul>
                      <li><a [routerLink]="['/support/contact-us']">Contact us</a></li>
                      <li><a [routerLink]="['/support/qc-pellets']">QC - Pellets</a></li>
                      <li><a [routerLink]="['/support/qc-nutraceuticals']">QC - Nutraceuticals</a></li>
                      <li><a [routerLink]="['/support/upcoming-trainings']">Upcoming Trainings</a></li>
                    </ul>
                  </div>
                </mat-expansion-panel>
              </div>
            </div>
          </div>
        </mat-accordion>
      </div>

      <div class="col-xl-3 col-lg-12 col-sm-12 col-md-12">
        <div class="footer-icons">
          <div class="payment-icons">
            <img src="../../../assets/img/visa.png" alt="">
            <img src="../../../assets/img/master-card.png" alt="">
            <img src="../../../assets/img/discover.png" alt="">
            <img src="../../../assets/img/amex.png" alt="">
          </div>
          <div class="certi-icons">
            <img src="../../../assets/img/Legacy-Logo.png" alt="Biote Medical" width="150" height="150">
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">

        <div class="footer-copyright mb-3">
          Copyright &copy; {{CopyWriteYear}} BIOTE MEDICAL,LLC. All rights reserved.
        </div>
      </div>
    </div>
  </div>
</footer>

<div *ngIf="isShow" (click)="gotoTop()" id="goToTop"></div>