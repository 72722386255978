import { Injectable, ErrorHandler } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { AppInsightsService } from './app-insights.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorService extends ErrorHandler {
  
  constructor(private appInsightsService: AppInsightsService){
    super();
  }

  handleError(error: Error) {
    this.appInsightsService.logException(error); // Manually log exception
  }

  getClientErrorMessage(error: Error): string {    
    return error.message ? 
           error.message : 
           error.toString();
  }

  getServerErrorMessage(error: HttpErrorResponse): string {
    return navigator.onLine ?    
           error.message :
           'No Internet Connection';
  }    
}