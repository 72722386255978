<ul class="navbar-nav me-auto mb-2 mb-lg-0 flex-nowrap">
  <ng-container *ngFor="let item of menuData">
    <ng-container *ngIf="item.children && item.children.length > 0">
      <ng-container *ngIf="hasPermission(item.moduleName)">
        <button class="btn btn-link"
                [matMenuTriggerFor]="menu.childMenu" #trigger="matMenuTrigger"
                [attr.menuid]="item.menuId" [ngClass]="{ active: item.menuId === parentId }">
          <span class="me-1">{{ item.title }}</span>
          <i class="fa fa-caret-down" *ngIf="!item.isOpen"></i>  <i class="fa fa-caret-up" *ngIf="item.isOpen"></i>  </button>
      </ng-container>
      <app-nav-menu-sub #menu [menuItems]="item.children" [parentChildMenuModules]="parentChildMenuModules"
        menuID="{{ menuID }}" (toggled)="item.isOpen = $event"></app-nav-menu-sub>
    </ng-container>
    <ng-container *ngIf="hasPermission(item.moduleName) && item.children.length == 0">
      <li class="nav-item">
        <button class="nav-link" [ngClass]="{ active: item.menuId === parentId }" [attr.menuid]="item.menuId"
          *ngIf="item.LinkHomeSite == menuID" [routerLink]="removeDomain(item.link)">
          {{ item.title }}
        </button>
        <a class="nav-link" *ngIf="item.LinkHomeSite != menuID && item.LinkHomeSite != 0 && (item.newWindow || item.newWindow == undefined)" href="{{ item.link }}"
          target="_blank">{{ item.title }}<span class="sr-only"></span></a>
        <a class="nav-link" *ngIf="item.LinkHomeSite != menuID && item.LinkHomeSite != 0 && !item.newWindow && item.newWindow !== undefined" href="{{ item.link }}">
                          {{ item.title }}<span class="sr-only"></span></a>
      </li>
    </ng-container>
  </ng-container>
</ul>