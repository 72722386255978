<div class="row height-initial mb-15" [ngClass]="{'justify-content-end': showExport}">
  <div class="col-md-8" *ngIf="showOmniBar">
    <mat-form-field class="full-width">
      <input #searchRef matInput placeholder="Search Text" (keyup)="onSubmit($event)" aria-label="Clinic"
        [formControl]="searchText">
      <button mat-button mat-icon-button matSuffix type="button" (click)="clearText()" id="table-clear-btn"
        *ngIf="searchRef.value">
        <mat-icon><i class="fa fa-times" aria-hidden="true"></i></mat-icon>
      </button>
      <button mat-button mat-icon-button matSuffix type="submit" (click)="searchData()" id="table-searchdata-btn"
        *ngIf="searchRef.value">
        <mat-icon><i class="fa fa-search" aria-hidden="true"></i></mat-icon>
      </button>
    </mat-form-field>
  </div>
  <div class="text-right col-md-4" *ngIf="showExport">
    <div *ngIf="print" class="d-ib">
      <button flat-button mat-stroked-button color="primary" [matMenuTriggerFor]="printOption" id="table-printdd-btn">
        <i class="fa fa-print"></i>&nbsp;Print Slip(s)
      </button>
      <mat-menu #printOption="matMenu">
        <button mat-menu-item class="btn-text-wrap" (click)="printSlips({type: 'pickingOnly', number: 'all'})"
          id="table-pickingall-btn">Picking
          Slips (All)</button>
        <button mat-menu-item class="btn-text-wrap" (click)="printSlips({type: 'pickingOnly', number: 'selected'})"
          id="table-pickingselected-btn">Picking Slips (Selected)</button>
        <hr>
        <button mat-menu-item class="btn-text-wrap" (click)="printSlips({type: 'both', number: 'all'})"
          id="table-pickingpackageall-btn">Picking &
          Packaging Slips (All)</button>
        <button mat-menu-item class="btn-text-wrap" (click)="printSlips({type: 'both', number: 'selected'})"
          id="table-packageselected-btn">Picking &
          Packaging Slips (Selected)</button>
      </mat-menu>
    </div>

    <!-- <button mat-button color="default" type="button" (click)="exportTable()" id="table-exceldwn-btn">
      Download as .xlsx <i class="fa fa-download" aria-hidden="true"></i>
    </button> -->
  </div>
</div>
<div class="material-grid-wrapper grid-height">
  <ng-container *ngIf="!hasTemplate">
    <table mat-table [dataSource]="dataSource" id="materialTable" matSort cdkDropListGroup
      (matSortChange)="customSortEvent($event)" cdkDropListGroup >
      <ng-container *ngFor="let column of columns; let i = index" [matColumnDef]="column.field">
        <ng-container *ngIf="column.field === 'select'">
          <th mat-header-cell *matHeaderCellDef [ngClass]="[column.cssClass !== undefined ? column.cssClass : '']">
            <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row; let i = index" [ngClass]="[column.cssClass !== undefined ? column.cssClass : '']">
            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)">
            </mat-checkbox>
          </td>
        </ng-container>
        <ng-container *ngIf="column.field !== 'select'">
          <th mat-header-cell mat-sort-header [disabled]="!column?.sorting" *matHeaderCellDef [ngClass]="[column.cssClass !== undefined ? column.cssClass : '']">
            {{ column.columnHeader }}
          </th>
          <td mat-cell *matCellDef="let row; let i = dataIndex" [ngClass]="[column.cssClass !== undefined ? column.cssClass : '']">
            <span *ngIf="!showTemplates(column); else icon" [className]="row[column.field]">
              <ng-container *ngIf="column.hasLink">              
                <a href="javascript:void(0)" (click)="navigationLink(row)" id="{{'table-nav-btn-'+row.id}}">{{
                  row[column.field] }}</a>
              </ng-container>
              <ng-container *ngIf="column.hasButton">
                 <a href="javascript:void(0)" (click)="navigationLink(row)">
                  {{column?.buttonText}}</a> 
              </ng-container>
              <ng-container *ngIf="column.isCurrency">
                {{ row[column.field] | currency:'USD' }}
              </ng-container>
              <ng-container *ngIf="column.inventoryToolsWksOnHnd">
                <span style="color:#3dab49; font-weight: bolder;" *ngIf="row[column.field] <= 4">{{ row[column.field] }}</span>
                <span style="color:#adad19; font-weight: bolder" *ngIf="row[column.field] > 4 && row[column.field] <= 8">{{ row[column.field] }}</span>
                <span style="color:#ef4660; font-weight: bolder" *ngIf="row[column.field] > 8">{{ row[column.field] }}</span>
              </ng-container>
              <ng-container *ngIf="!column.hasLink && !column.hasCheckbox && !column.hasButton && !column.hasDownloadLink && !column.isCurrency && !column.isAddress && !column.inventoryToolsWksOnHnd">
                {{ row[column.field] }}
              </ng-container>
              <ng-container *ngIf="column.isAddress">
                <p *ngIf="row[column.field].company">{{ row[column.field].company }}</p>
                <p *ngIf="row[column.field].address1">{{ row[column.field].address1 }}</p>
                <p *ngIf="row[column.field].address2">{{ row[column.field].address2 }}</p>
                <p>{{ row[column.field].city }}, {{ row[column.field].stateProvince }}, {{ row[column.field].zipPostalCode }}</p>
                <p>{{ row[column.field].country }}</p>
              </ng-container>
              <ng-container *ngIf="column.hasCheckbox">
                <mat-checkbox (change)="checkboxChange($event,row, column.field)"
                  id="{{'table-check-'+row.id+'-'+column.columnHeader}}" [checked]="row[column.field]">
                </mat-checkbox>
              </ng-container>
              <ng-container *ngIf="column.hasDownloadLink">
                <ng-container *ngIf="row[column.field] ; else noFile">
                  <a [href]="row[column.field]">Download</a> 
                 </ng-container>
                 <ng-template #noFile>
                   No file attached
                 </ng-template>
              </ng-container>
            </span>
            <ng-template #icon>
              <ng-container *ngIf="column.booleanField === true">
                <div *ngIf="row[column.field]">
                  <mat-icon class="grid-icon" color="primary">check</mat-icon>
                </div>
                <div *ngIf="!row[column.field]">
                  <mat-icon class="grid-icon" color="warn">close</mat-icon>
                </div>
              </ng-container>
              <ng-container *ngIf="column.field === 'action'">

                <mat-icon class="grid-icon mid-grey" *ngIf="column.actions.includes('edit')" type="button" mat-icon-button
                (click)="actionEvent(row, 'edit')">mode_edit</mat-icon>
                <!-- <button *ngIf="column.actions.includes('edit')" type="button" mat-icon-button
                  (click)="actionEvent(row, 'edit')" id="{{'table-edit-btn-'+row.id}}">
                  <i class="fa fa-pencil"></i>
                </button> -->

                <button *ngIf="column.actions.includes('assignMentor')" type="button" mat-icon-button
                  (click)="actionEvent(row, 'assignMentor')" id="{{'table-assignMentor-btn-'+row.id}}">
                  <i class="fa fa-address-card-o" aria-hidden="true"></i>
                </button>

                <!-- <button *ngIf="column.actions.includes('delete')" type="button" (click)="actionEvent(row,'delete')"
                  mat-icon-button id="{{'table-delete-btn-'+row.id}}">
                  <i class="fa fa-trash"></i>
                </button> -->

                <mat-icon class="grid-icon mid-grey" *ngIf="column.actions.includes('delete')" type="button" mat-icon-button
                  (click)="actionEvent(row, 'delete')">delete</mat-icon>

                <button *ngIf="column.actions.includes('view')" type="button" (click)="actionEvent(row,'view')"
                  mat-icon-button id="{{'table-view-btn-'+row.id}}">
                  <i class="fa fa-search-plus"></i>
                </button>
              </ng-container>

            </ng-template>

            <ng-container *ngIf="column.field === 'resubmitIcon'">
              <a *ngIf="row.statusName === 'FAILED'" href="javascript:void(0)" id="{{'table-resubmit-btn-'+row.id}}"
                (click)="actionEvent(row,'resubmit')"><i class="fa fa-repeat"></i>&nbsp;Resubmit</a>
              <span *ngIf="row.statusName != 'FAILED'">--</span>
            </ng-container>
          </td>
        </ng-container>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" [ngClass]="{ 'on-hold': (row.holdOrders === true || row.isPastReport === true)}">
      </tr>
    </table>
  </ng-container>
  <ng-container *ngIf="hasTemplate">
    <table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows id="materialTable"
      (matSortChange)="customSortEvent($event)">
      <ng-container *ngFor="let column of columns; let i = index" [matColumnDef]="column.field">        
        <ng-container *ngIf="column.field === 'select'">
          <th mat-header-cell *matHeaderCellDef [ngClass]="[column.cssClass !== undefined ? column.cssClass : '']">
            <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)">
            </mat-checkbox>
          </td>
        </ng-container>
        <ng-container *ngIf="column.field=== 'caret'">
          <th mat-header-cell *matHeaderCellDef [ngClass]="[column.cssClass !== undefined ? column.cssClass : '']"></th>
          <td mat-cell *matCellDef="let element">
            <ng-container *ngIf="expandedElement === element"><img (click)="expandedElement = expandedElement === element ? null : element;toggleRow(element, expandedElement)" src="../../../assets/img/expand_less.png"></ng-container>
            <ng-container *ngIf="expandedElement !== element"><img (click)="expandedElement = expandedElement === element ? null : element;toggleRow(element, expandedElement)" src="../../../assets/img/expand_more.png"></ng-container>
          </td>
        </ng-container>
        <ng-container *ngIf="column.field !== 'select'&&column.field !== 'caret'">
          <th mat-header-cell mat-sort-header [disabled]="!column?.sorting" *matHeaderCellDef [ngClass]="[column.cssClass !== undefined ? column.cssClass : '']">
            {{ column.columnHeader }}
          </th>
          <td mat-cell *matCellDef="let row; let i = dataIndex" [ngClass]="[column.cssClass !== undefined ? column.cssClass : '']">
            <span *ngIf="!showTemplates(column); else icon" [ngStyle]="getStyle(row[column.field])">
              <ng-container *ngIf="column.hasLink">
                <a href="#">{{ row[column.field] }}</a>
              </ng-container>
              <ng-container *ngIf="column.imageField">
                <img [src]="row[column.field]"/>
              </ng-container>
              <ng-container *ngIf="!column.hasLink && !column.imageField">
                {{ row[column.field] }}
              </ng-container>
            </span>

            <ng-template #icon>
              <ng-container *ngIf="column.booleanField === true">
                <div *ngIf="row[column.field]">
                  <mat-icon class="grid-icon" color="primary">check</mat-icon>
                </div>
                <div *ngIf="!row[column.field]">
                  <mat-icon class="grid-icon" color="warn">close</mat-icon>
                </div>
              </ng-container>
              <ng-container *ngIf="column.field === 'action'">

                <button *ngIf="column.actions.includes('edit')" type="button" mat-icon-button
                  (click)="actionEvent(row, 'edit')" id="{{'table-edit-btn-'+row.id}}">
                  <i class="fa fa-pencil"></i>
                </button>

                <button *ngIf="column.actions.includes('delete')" type="button" (click)="actionEvent(row, 'delete')"
                  mat-icon-button id="{{'table-delete-btn-'+row.id}}">
                  <i class="fa fa-trash"></i>
                </button>

                <button *ngIf="column.actions.includes('view')" type="button" (click)="actionEvent(row, 'view')"
                  mat-icon-button id="{{'table-view-btn-'+row.id}}">
                  <i class="fa fa-search-plus"></i>
                </button>
              </ng-container>
            </ng-template>

            <ng-container *ngIf="column.field === 'resubmitIcon'">
              <a *ngIf="row.statusName === 'FAILED'" href="javascript:void(0)" (click)="actionEvent(row,'resubmit')"
                id="{{'table-resubmit-btn-'+row.id}}"><i class="fa fa-repeat"></i>&nbsp;Resubmit</a>
              <span *ngIf="row.statusName != 'FAILED'">--</span>
            </ng-container>
          </td>
        </ng-container>
      </ng-container>
      <ng-container matColumnDef="expandedDetail">
         <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
          <div class="example-element-detail" *ngIf="sharedTabData?.length" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">

            <div class="inner-table mat-elevation-z8" *ngIf="expandedElement">

              <div *ngIf="renderOrderDetail" class="track-info">
                  <strong>Tracking Number: </strong>
                     <a *ngIf="orderTrackingObj[0]" href="javaScript:void(0)" (click)="navigateToCarrier(orderTrackingObj[0])">{{orderTrackingObj[0]?.track_number}}</a>
                     <span class="d-inline" *ngIf="!orderTrackingObj[0]?.track_number">Not Available</span>
              </div>

              <table #innerTables mat-table (matSortChange)="innerCustomSortEvent($event)" [dataSource]="sharedTabData" matSort multiTemplateDataRows>                
                <ng-container matColumnDef="{{innerColumn.field}}" *ngFor="let innerColumn of innerColumns">
                  <ng-container *ngIf="innerColumn.field=== 'caret'">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!innerColumn?.sorting" [ngClass]="[innerColumn.cssClass !== undefined ? innerColumn.cssClass : '']"></th>
                    <td mat-cell *matCellDef="let innerElement" >
                      <ng-container *ngIf="innerExpandedElement === innerElement"><img (click)="innerExpandedElement = innerExpandedElement === innerElement ? null : innerElement;toggleInnerRow(innerElement, innerExpandedElement)" src="assets/img/expand_less.png"></ng-container>
                      <ng-container *ngIf="innerExpandedElement !== innerElement"><img (click)="innerExpandedElement = innerExpandedElement === innerElement ? null : innerElement;toggleInnerRow(innerElement, innerExpandedElement)" src="assets/img/expand_more.png"></ng-container>
                      </td>
                  </ng-container>
                  <ng-container *ngIf="innerColumn.field === 'action'">
                    <th mat-header-cell mat-sort-header [disabled]="!innerColumn?.sorting" *matHeaderCellDef [ngClass]="[innerColumn.cssClass !== undefined ? innerColumn.cssClass : '']">Action</th>
                    <td mat-cell *matCellDef="let row; let i = dataIndex" >
                      <button *ngIf="innerColumn.actions.includes('edit')" type="button" mat-icon-button
                        (click)="innerActionEvent(row, 'edit')" id="{{'table-edit-btn-'+row.id}}">
                        <i class="fa fa-pencil"></i>
                      </button>
      
                      <button *ngIf="innerColumn.actions.includes('delete')" type="button" (click)="innerActionEvent(row, 'delete')"
                        mat-icon-button id="{{'table-delete-btn-'+row.id}}">
                        <i class="fa fa-trash"></i>
                      </button>
      
                      <button *ngIf="innerColumn.actions.includes('view')" type="button" (click)="innerActionEvent(row, 'view')"
                        mat-icon-button id="{{'table-view-btn-'+row.id}}">
                        <i class="fa fa-search-plus"></i>
                      </button>
                    </td>
                  </ng-container>
                  <ng-container *ngIf="innerColumn.field !== 'caret' && innerColumn.field !== 'action'">
                    <th mat-header-cell mat-sort-header [disabled]="!innerColumn?.sorting" *matHeaderCellDef mat-sort-header [ngClass]="[innerColumn.cssClass !== undefined ? innerColumn.cssClass : '']"> {{innerColumn.columnHeader}} </th>
                    <td mat-cell *matCellDef="let innerElement" [ngClass]="[innerColumn.cssClass !== undefined ? innerColumn.cssClass : '']"><span  [ngStyle]="getInnerStyle(innerElement[innerColumn.field])" [ngStyle]="getStyle(innerElement[innerColumn.field])"> {{innerElement[innerColumn.field]}} </span></td>
                  </ng-container>
                </ng-container>
                <ng-container matColumnDef="innerExpandedDetail">
                  <td mat-cell *matCellDef="let innerElement" [attr.colspan]="innerDisplayedColumns.length">
                   <div class="example-element-detail" *ngIf="sharedInnerMostTabData?.length" [@detailExpand]="innerElement == innerExpandedElement ? 'expanded' : 'collapsed'">
                     <div class="inner-table mat-elevation-z8" *ngIf="innerExpandedElement">
                       <table #innerMostTables mat-table (matSortChange)="innerMostCustomSortEvent($event)" [dataSource]="sharedInnerMostTabData" matSort>
                         <ng-container matColumnDef="{{innerMostColumn.field}}" *ngFor="let innerMostColumn of innerMostColumns">
                           <th mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="[innerMostColumn.cssClass !== undefined ? innerMostColumn.cssClass : '']"> {{innerMostColumn.columnHeader}} </th>
                           <td mat-cell *matCellDef="let element"> {{element[innerMostColumn.field]}} </td>
                         </ng-container>
                         
                         <tr mat-header-row *matHeaderRowDef="innerMostDisplayedColumns"></tr>
                         <tr mat-row *matRowDef="let row; columns: innerMostDisplayedColumns;"></tr>
                       </table>
                     </div>
                   </div>
                 </td>
               </ng-container>
                <tr mat-header-row *matHeaderRowDef="innerDisplayedColumns;"></tr>
                <tr mat-row *matRowDef="let innerElement; columns: innerDisplayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: ['innerExpandedDetail']" class="example-detail-row"></tr>
              </table>
            </div>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let element; columns: displayedColumns" [class.example-expanded-row]="expandedElement === element"></tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
      <tr mat-footer-row >test</tr>
    </table>
  </ng-container>
  <mat-paginator *ngIf="clientPagination==true" [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
</div>