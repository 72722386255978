import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {

  message: string = "Are you sure?"
  confirmButtonText = "Yes"
  cancelButtonText = "Cancel"
  isConfirmation: boolean = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    public dialogRef: MatDialogRef<ConfirmDialogComponent>) {
    dialogRef.disableClose = true;
    if (data) {
      this.message = data.message || this.message;
      this.isConfirmation = data.isConfirmation ?  this.isConfirmation : data.isConfirmation;
      if (data.confirmButtonText || data.cancelButtonText ) {
        this.confirmButtonText = data.confirmButtonText || this.confirmButtonText;
        this.cancelButtonText = data.cancelButtonText || this.cancelButtonText;
      }
      if (data.buttonText) {
        this.confirmButtonText = data.buttonText.ok || this.confirmButtonText;
        this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
      }
    }
  }

  onConfirmClick(): void {
    this.dialogRef.close(true);
  }


}


