import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { orders, clinicEffectiveDate, magentoStatusCode } from '../queries/order-history-queries';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { GraphQLModule } from 'src/app/graphql.module';
import { AppConfig } from 'src/app/app.config.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class OrderHistoryService {

  protected apiServer = AppConfig.configurations.apiServer;
  headers: HttpHeaders;


  constructor(private apollo: Apollo, private http: HttpClient) {
  }
  public getOrders(args) {
    return this.apollo.query<any>({
      query: orders,
      variables: {
        first: args.first,
        last: args.last,
        after: args.after,
        before: args.before,
        where: args.filter ? args.filter : null,
        order: args.orderBy ? args.orderBy : null,
      }
    }).pipe(map(({ data }) => data));
  }

  public getClinicsMagentoOrderDetail(url) {
    const magentourl = this.apiServer.magentoApiURL + url;
    return this.http.get<any>(magentourl, {
    });
  }

  public getClinicEffectiveDate(args): Observable<any> {
    return this.apollo.query<any>({
      query: clinicEffectiveDate,
      variables: {
        where: args.filter ? args.filter : null
      }
    }).pipe(map(({ data }) => data.clinics.nodes[0]));
  }

  public getStatusCodeValue(args) {
    return this.apollo.query<any>({
      query: magentoStatusCode,
      variables: {
        where: args.filter ? args.filter : null
      }
    }).pipe(map(({ data }) => data.magentoStatusCodes.nodes));
  }
}