<mat-menu #childMenu="matMenu" [overlapTrigger]="false">
  <ng-container *ngFor="let child of menuItems">
    <mat-accordion *ngIf="child.children && child.children.length > 0">
      <mat-expansion-panel
        (opened)="panelOpenState = true"
        (closed)="panelOpenState = false"
      >
        <mat-expansion-panel-header (click)="$event.stopPropagation()">
          <mat-panel-title>
            {{ child.title }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <span *ngFor="let child of child.children">
          <button
            mat-menu-item
            [routerLink]="removeDomain(child.link)"
            (click)="buttonClick(child)"
          >
            {{ child.title }}
          </button>
        </span>
      </mat-expansion-panel>
    </mat-accordion>
    <span *ngIf="!child.children || child.children.length === 0">
      <span *ngIf="hasPermission(child.moduleName)">
        <button
          mat-menu-item
          [routerLink]="removeDomain(child.link)"
          *ngIf="child.LinkHomeSite == menuID || child.LinkHomeSite == 0"
        >
          {{ child.title }}
        </button>
      </span>
      <a
        href="{{ child.link }}"
        target="_blank"
        *ngIf="child.LinkHomeSite != menuID && child.LinkHomeSite != 0"
        mat-menu-item
        ><span>{{ child.title }}</span></a
      >
    </span>
  </ng-container>
</mat-menu>
