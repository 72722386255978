import { INavSelectorClinicList } from "./INavSelectorClinicList";
import { AdminNavSelectorClinicList } from "./admin-nav-selector-clinic-list";
import { NonAdminNavSelectorClinicList } from "./non-admin-nav-selector-clinic-list";


export class NavSelectorFactory {
    public static getClinicByPermission(permission: string[]): INavSelectorClinicList {
        if (permission.find(x => x == "read:isadmin") === "read:isadmin") {
            return new AdminNavSelectorClinicList();
        } else {
            return new NonAdminNavSelectorClinicList();
        }
        return null;
    }
}