import { Injectable } from '@angular/core';
import { OrderStatus, PaymentStatus, ShippingStatus } from 'src/app/shared/enums/all-status-enum';
import { Node, OrderNode, OrderItem, OrderItemDetail, Track, MagentoStatusCode } from './../../../shared/models/orders';
import { dateTimeFormat, getDateToDisplay, getInputDate, getMangentoDate } from 'src/app/common/date/date';
import { OrderHistoryService } from 'src/app/services/graphql/services/order-history.service';
import { UniqueDirectiveNamesRule } from 'graphql';
@Injectable({
  providedIn: 'root'
})
export class OrdersMappingService {

  magentoStatusCode: MagentoStatusCode[] = [];
  constructor(private orderHistoryService: OrderHistoryService) { }


  getStatusCodeValue() {
    const payload = {} as any;
    this.orderHistoryService.getStatusCodeValue(payload).subscribe(
      (res) => {
        this.magentoStatusCode = res;
      }
    );
  }
  mappingOrderList(response) {
    const ordersData: Node[] = [];
    this.getStatusCodeValue();
    response.forEach(data => {
      let order: any = { createdOnUtc: '', orderStatusId: '', paymentStatusId: '', shippingStatusId: '', storeName: '' };
      order.createdOnUtc = getDateToDisplay(getInputDate(data.created_at));
      order.orderStatusId = this.getStatusValue(data.status);
      order.paymentStatusId = data.payment.account_status;
      order.shippingStatusId = data.shipping_description;
      order.storeName = data.store_name;
      order.id = data.entity_id;
      order.orderTotal = this.currencyFormatter(data.grand_total);
      order.entity_id = data.entity_id;

      data.items.forEach(data => {
        const orderItem = data;
        orderItem.quantity = orderItem.qty_ordered;
        orderItem.priceInclTax = this.currencyFormatter(orderItem.price_incl_tax);
        orderItem.unitPriceInclTax = this.currencyFormatter(orderItem.original_price);
        orderItem.base_row_total_incl_tax = this.currencyFormatter(orderItem.base_row_total_incl_tax);
      });
      order.orderItem = data.items;
      ordersData.push(order);

    });
    return ordersData;
  }

  getStatusValue(statusCode) {
    let status = this.magentoStatusCode.find(x => x.statusCode == statusCode);
    if (status != undefined)
      return status.status;
    else
      return statusCode;
  }

  currencyFormatter(value) {
    if (value != undefined) {
      return (value).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      });
    }
  }
  mappingOrderLegecyList(response): Node[] {

    const ordersData: Node[] = [];
    if (response) {
      response.forEach(data => {
        const order = data.node;
        order.createdOnUtc = getDateToDisplay(getInputDate(order.createdOnUtc));
        order.orderStatusId = OrderStatus[order.orderStatusId];
        order.paymentStatusId = PaymentStatus[order.paymentStatusId];
        order.shippingStatusId = ShippingStatus[order.shippingStatusId];
        order.storeName = (order.Store !== null) ? order.Store.name : 'Unknown';
        order.orderTotal = this.currencyFormatter(order.orderTotal);
        order.orderItem.forEach(data => {
          const orderItem = data;
          orderItem.name = orderItem.product?.name;
          orderItem.priceInclTax = this.currencyFormatter(orderItem.priceInclTax);
          orderItem.unitPriceInclTax = this.currencyFormatter(orderItem.unitPriceInclTax);
        });
        ordersData.push(order);
      });
    }
    return ordersData;
  }

  getAttachmentDetail(data) {
    let downloadLink = null;
    if (data && data.length > 0
      && data[0].attachments) {

      if (data[0].attachments.id && data[0].attachments.publicUri) {
        downloadLink = data[0].attachments.publicUri;
      }
    }
    return downloadLink;
  }

  mappingOrderNotesList(response): OrderNode[] {
    const orderNotesData: OrderNode[] = [];
    response.forEach(item => {
      const note: OrderNode = item;
      note.createdOnUtc = dateTimeFormat(note.createdOnUtc);
      note.attachmentId = (item.orderNotesAttachments &&
        item.orderNotesAttachments.length > 0 &&
        item.orderNotesAttachments[0].attachmentId)
        ? item.orderNotesAttachments[0].attachmentId : null;
      note.attachmentPublicUri = this.getAttachmentDetail(item.orderNotesAttachments);

      orderNotesData.push(note);
    });
    return orderNotesData;
  }


  mappingLegacyOrderProductsList(response): OrderItemDetail[] {
    const orderProductData: OrderItemDetail[] = [];
    response.forEach(item => {
      const product: any = {};
      product.price = item.unitPriceInclTax;
      product.name = item.name;
      product.qty_invoiced = item.quantity;
      product.row_invoiced = item.priceInclTax;
      orderProductData.push(product);
    });
    return orderProductData;
  }

  mappingOrderProductsList(response): OrderItemDetail[] {
    const orderProductData: OrderItemDetail[] = [];
    response.forEach(item => {
      const product: OrderItemDetail = item;
      item.price = `$${item.price.toString()}`;
      item.row_invoiced = `$${item.row_invoiced.toString()}`;
      orderProductData.push(product);
    });
    return orderProductData;
  }

  mappingOrderShippmentList(response): Track[] {
    const shippmentData: Track[] = [];
    response.forEach(item => {
      const shippment: Track = item;
      item.created_at = item.created_at ? getDateToDisplay(getInputDate(item.created_at)) : '';
      item.updated_at = item.updated_at ? getDateToDisplay(getInputDate(item.updated_at)) : '';
      shippmentData.push(shippment);
    });
    return shippmentData;
  }

  mappingLegacyOrderShippmentList(response): Track[] {
    const shippmentData: Track[] = [];
    response.forEach(item => {
      const shipment: any = {};
      shipment.track_number = item.trackingNumber;
      shipment.created_at = item.createdOnUtc ? getDateToDisplay(getInputDate(item.createdOnUtc)) : '';
      shippmentData.push(shipment);
    });
    return shippmentData;
  }

  mappingOrdersList(response): OrderItem[] {
    const orderProductData: OrderItem[] = [];
    response.forEach(item => {
      const product: OrderItem = item;
      orderProductData.push(product);
    });
    return orderProductData;
  }

}
