
<div class="page-wrap d-flex flex-row align-items-center">
  <div class="container">
      <div class="row justify-content-center">
          <div class="col-md-12 text-center">
              <span class="display-1 d-block">SORRY</span>
              <div class="mb-4 lead"><strong>You don't have access to this page.</strong></div>
              
          </div>
      </div>
  </div>
</div>