import { Component, OnInit, Output, EventEmitter, AfterViewInit, HostListener } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { AuthService } from 'src/app/auth/auth.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { trigger, transition, style, animate, state } from '@angular/animations';
import { ClinicService } from 'src/app/services/graphql/services/clinic.service';
import { ShareDataService } from 'src/app/services/share-data.service';
import { indicate } from 'src/app/loader-operator';
import { TranslateService } from '@ngx-translate/core';
import { base_customer_profile, customer_profile } from 'src/app/shared/models/customer-profile';
import { ProcedureCartSummaryService } from 'src/app/services/procedure-cart-summary.service';
import { ClinicMenuService } from 'src/app/services/clinic-service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppConfig } from 'src/app/app.config.service';
import { Router, NavigationEnd  } from '@angular/router';
@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  animations: [
    trigger('mobileMenu', [
      state('closed', style({
        height: '0',
        overflow: 'hidden',
        opacity: 0
      })),
      state('open', style({
        height: '*'
      })),
      transition('* => *', animate(150))
    ]),
  ]
})
export class ToolbarComponent implements OnInit, AfterViewInit {
  loading$ = new BehaviorSubject<boolean>(false);
  public static clinicId = '1';
  cId = '';
  clinicList = [];
  paginationArgs = {
    filter: {}
  };
  langId = 'en';
  langList = [{ key: 'English', value: 'en' }, { key: 'French', value: 'fr' }]
  scrHeight;
  scrWidth;
  toggleMobileMenu;
  state;
  public isImpersonated : boolean;
  isCollapsed = true;
  profilePic :any;
  name:string;
  sub: Subscription;
  customerprofile: base_customer_profile = new customer_profile;
  public holdOrders: boolean = false;
  private portal = AppConfig.configurations.portalUrl;
  @Output() leftPanel = new EventEmitter();
  profile : any;
  constructor(
    public app: AppComponent,
    public auth: AuthService,
    public clinicSvc: ClinicService,
    public shareDataSvc: ShareDataService,
    public translate: TranslateService,
    private shareDataService: ShareDataService,
    public procedurecartSvc: ProcedureCartSummaryService,
    private clinicService: ClinicService,
    private clinicMenuService: ClinicMenuService,
    private snackBar: MatSnackBar,
    private router: Router
  ) {
    this.getScreenSize();
    this.translate.addLangs(['en', 'fr']);
    this.translate.setDefaultLang('en');
  }

  ngAfterViewInit(): void {
    this.getClinics();
  }

  ngOnInit() {
    this.auth.userProfile$.subscribe(
      (res) => {
        this.profilePic = res.picture;
        this.name = res.name;
      }
    );
    this.getClinics();
    this.cId = ToolbarComponent.clinicId;
    this.isImpersonated = this.shareDataSvc.isImpersonated;
    this.getProcedureCartSummary();
    this.shareDataService.notifyObservable$.subscribe(res => {
      if (res.refreshCartComponent) {
        this.getProcedureCartSummary();
      }
    })
    this.sub = this.shareDataService.getCustomerProfile().subscribe(
      (data) => {
        if (data) {
          this.profile = data;
        }
      }
    );

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if(!this.isCollapsed){
          this.toggleCollapse();
        }
      }
    });
  }

  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }

  showLeftPanel() {
    this.leftPanel.emit();
  }

  langChanged(e) {
    this.langId = e;
    this.translate.use(this.langId);
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    setTimeout(() => {
      this.scrHeight = window.innerHeight;
      this.scrWidth = window.innerWidth;
      if (this.scrWidth < 1024) {
        this.state = 'closed';
      } else {
        this.state = 'open';
      }
    }, 0);
  }

  toggleMenu() {
    this.state === 'closed' ? (this.state = 'open') : (this.state = 'closed');
  }

  getClinics() {
    this.clinicSvc.getHeaderClinics(this.paginationArgs).pipe(indicate(this.loading$)).subscribe(clinic => {
      this.clinicList = clinic["nodes"];
    });
  }
  getProcedureCartSummary() {
    this.sub = this.shareDataService.getCustomerProfile().subscribe(
      (data) => {
        if (data) {
          this.customerprofile = data;
          if (data.user_metadata) {
            this.getClinicInfo();
          } else {
            this.getDefaultClinicId();
          }
        }
      }
    );

  }
  getClinicInfo() {
    var clinic = this.customerprofile.clinicId;

    const payload = {} as any;
    payload.filter = { id: { eq:  Number(this.customerprofile.clinicId)} };

    this.procedurecartSvc.getProcedureCartSummary(clinic).pipe().subscribe(proc => {
      this.clinicService.getClinicInfo(payload).subscribe(
        (res: any) => {
          this.holdOrders = res.holdOrders;
        }
      );

    });
  }

  getDefaultClinicId() {
    this.clinicMenuService.clinicQuery({ filter: { customerID: {"eq" :Number(this.customerprofile.customerId)} }, first: 30, orderBy: { clinicName: 'ASC' } }).pipe().subscribe(clinic => {
      if (this.customerprofile.clinicId) {
        this.getClinicInfo();
      } else {
        if (clinic.length > 0) {
          this.customerprofile.clinicId = clinic[0]?.clinicID;
          this.getClinicInfo();
        } else {

          if (this.shareDataService.isImpersonated) {
            this.snackBar.open('Clinics not found for this user. Redirecting to Admin Portal.', 'X', { panelClass: ['error'], duration: 5000 });
            setTimeout(() => {
              window.location.href = this.portal.adminPortalUrl;
            }, 5000);
          } else {
            this.snackBar.open('No Clinics are mapped. Please contact Customer Service.', 'X', { panelClass: ['error'], duration: 5000 });
            setTimeout(() => {
              this.auth.logout();
            }, 5000);
          }

        }
      }
    });
  }

}
