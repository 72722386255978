import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { customer_profile } from '../shared/models/customer-profile';

@Injectable({
  providedIn: 'root'
})
export class ShareDataService {

  private edit = new BehaviorSubject<any>(null);
  private customer_profileSub = new ReplaySubject<customer_profile>(0);
  public notify = new BehaviorSubject<any>('');
  notifyObservable$ = this.notify.asObservable();
  private token = new BehaviorSubject<any>(null);
  private tokenAvailable = new ReplaySubject<boolean>(1);
  isTokenAvailable = this.tokenAvailable.asObservable();
  isImpersonationActive = false;
  public impersonationStateChange = new BehaviorSubject<any>('');
  impersonationStateChangeObservable$ = this.impersonationStateChange.asObservable();
  
  setData(data) {
    this.edit.next(data);
  }

  getData(): Observable<any> {
    return this.edit.asObservable();
  }

  setToken(data) {
    this.token.next(data);
    this.tokenAvailable.next(true);
  }

  getToken(): Observable<any> {
    return this.token.asObservable();
  }

  setCustomerProfile(data: customer_profile) {
    this.customer_profileSub.next(data);
  }

  getCustomerProfile(): Observable<any> {
    return this.customer_profileSub.asObservable();
  }
  public notifyOther(data: any) {
    if (data) {
      this.notify.next(data);
    }
  }

  get isImpersonated() {
    return this.isImpersonationActive;
  }

  public setImpersonation(flag: any) {
    this.isImpersonationActive = flag;
      this.impersonationStateChange.next(flag);
  }
}
