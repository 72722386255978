import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { AppConfig } from 'src/app/app.config.service';
import { AuthService } from 'src/app/auth/auth.service';
import { Auth0Service } from 'src/app/services/auth0.service';
import { ClinicMenuService } from 'src/app/services/clinic-service';
import { ClinicService } from 'src/app/services/graphql/services/clinic.service';
import { ShareDataService } from 'src/app/services/share-data.service';
import { base_customer_profile, customer_profile } from 'src/app/shared/models/customer-profile';

@Component({
  selector: 'app-header-userinfo',
  templateUrl: './header-userinfo.component.html',
  styleUrls: ['./header-userinfo.component.scss']
})
export class HeaderUserinfoComponent implements OnInit {

  clinicData;
  name = '';
  clinicAddress = [];
  customerprofile: base_customer_profile = new customer_profile;
  sub: Subscription;
  private portal = AppConfig.configurations.portalUrl;

  constructor(public auth: AuthService, private clinicService: ClinicService, private auth0service: Auth0Service, private data: ShareDataService, private clinicMenuService: ClinicMenuService, private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.auth.userProfile$.subscribe(
      (res) => {
        this.name = res.name;
      }
    );
    this.sub = this.data.getCustomerProfile().subscribe(
      (data) => {
        if (data) {
          this.customerprofile = data;
          this.customerprofile.name ? this.name = this.customerprofile.name : this.name;
          if (data.user_metadata) {
            this.getClinicAddress();
          } else {
            this.getDefaultClinicId();
          }
        }
      }
    );
  }

  getDefaultClinicId() {
    this.clinicMenuService.clinicQuery({ filter: { customerID: {"eq" : Number(this.customerprofile.customerId)} }, first: 30, orderBy: { clinicName: 'ASC' } }).pipe().subscribe(clinic => {
      if (this.customerprofile.clinicId) {
        this.getClinicAddress();
      } else if (clinic.length > 0) {
        this.customerprofile.clinicId = clinic[0]?.clinicID;
        this.getClinicAddress();
      } else {
        if (this.data.isImpersonated) {
          this.snackBar.open('Clinics not found for this user. Redirecting to Admin Portal.', 'X', { panelClass: ['error'], duration: 5000 });
          setTimeout(() => {
            window.location.href = this.portal.adminPortalUrl;
          }, 5000);
        } else {
          this.snackBar.open('No Clinics are mapped. Please contact Customer Service.', 'X', { panelClass: ['error'], duration: 5000 });
          setTimeout(() => {
            this.auth.logout();
          }, 5000);
        }
      }
    });
  }

  getClinicAddress() {
    this.auth0service.getUsersClinic(this.customerprofile.customerId).subscribe(
      (data) => {
        const currentClinicId = this.customerprofile.clinicId;
        const payload = {} as any;
        payload.filter = { id:  { eq: Number(currentClinicId) } };
        this.clinicService.getClinicAddress(payload).subscribe(
          (res: any) => {
            this.clinicData = res;
            this.clinicAddress = this.clinicData.ClinicAddresses.filter(
              (item) => {
                return item.address.isPrimary;
              }
            );

            if (this.clinicAddress.length === 0) {
              this.clinicAddress.push(this.clinicData.ClinicAddresses[0]);
            }

          }
        );
      }
    );
  }
}
