import { DecimalPipe } from '@angular/common';
import { AppConfig } from 'src/app/app.config.service';
import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { ProcedureCartSummaryService } from 'src/app/services/procedure-cart-summary.service';
import { base_customer_profile, customer_profile } from 'src/app/shared/models/customer-profile';
import { ShareDataService } from 'src/app/services/share-data.service';
import { Subscription } from 'rxjs';
import { ClinicService } from 'src/app/services/graphql/services/clinic.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ClinicMenuService } from 'src/app/services/clinic-service';


@Component({
  selector: 'app-procedure-cart-summary',
  templateUrl: './procedure-cart-summary.component.html',
  styleUrls: ['./procedure-cart-summary.component.scss']
})
export class ProcedureCartSummaryComponent implements OnInit {
  public procedureCartSummarySum: DecimalPipe;
  public totalProcedure: number;
  sub: Subscription;
  private portal = AppConfig.configurations.portalUrl;

  public holdOrders: boolean = false;
  customerprofile: base_customer_profile = new customer_profile;
  constructor(
    public procedurecartSvc: ProcedureCartSummaryService,
    private shareDataService: ShareDataService,
    private clinicService: ClinicService, private clinicMenuService: ClinicMenuService, private snackBar: MatSnackBar, private auth: AuthService) { }

  ngOnInit() {
    this.getProcedureCartSummary();
    this.shareDataService.notifyObservable$.subscribe(res => {
      if (res.refreshCartComponent) {
        this.getProcedureCartSummary();
      }
    })
  }

  getProcedureCartSummary() {
    this.sub = this.shareDataService.getCustomerProfile().subscribe(
      (data) => {
        if (data) {
          this.customerprofile = data;
          if (data.user_metadata) {
            this.getClinicInfo();
          } else {
            this.getDefaultClinicId();
          }
        }
      }
    );

  }

  getDefaultClinicId() {
    this.clinicMenuService.clinicQuery({ filter: { customerID: {"eq" :Number(this.customerprofile.customerId)} }, first: 30, orderBy: { clinicName: 'ASC' } }).pipe().subscribe(clinic => {
      if (this.customerprofile.clinicId) {
        this.getClinicInfo();
      } else {
        if (clinic.length > 0) {
          this.customerprofile.clinicId = clinic[0]?.clinicID;
          this.getClinicInfo();
        } else {

          if (this.shareDataService.isImpersonated) {
            this.snackBar.open('Clinics not found for this user. Redirecting to Admin Portal.', 'X', { panelClass: ['error'], duration: 5000 });
            setTimeout(() => {
              window.location.href = this.portal.adminPortalUrl;
            }, 5000);
          } else {
            this.snackBar.open('No Clinics are mapped. Please contact Customer Service.', 'X', { panelClass: ['error'], duration: 5000 });
            setTimeout(() => {
              this.auth.logout();
            }, 5000);
          }

        }
      }
    });
  }

  getClinicInfo() {
    var clinic = this.customerprofile.clinicId;

    const payload = {} as any;
    payload.filter = { id: { eq:  Number(this.customerprofile.clinicId)} };

    this.procedurecartSvc.getProcedureCartSummary(clinic).pipe().subscribe(proc => {
      this.clinicService.getClinicInfo(payload).subscribe(
        (res: any) => {
          this.holdOrders = res.holdOrders;
          this.procedureCartSummarySum = proc[0].procedureSummarySum;
          this.totalProcedure = proc[0].totalProcedure;
        }
      );

    });
  }
}
