import gql from 'graphql-tag';
import { CLINIC_ATTRIBUTES, PRIMARY_ADDRESS, CLINIC_GROUPS } from '../fragment/clinic-fragment';

export const clinicHeader = gql`
query{
  clinics{
    totalCount
    nodes{
      id
      clinicBusinessName
      acceptHMO
      acceptInsurance
      acceptPPO
      active
      allowCheckMoneyOrder
    }
  }
}`;

export const clinics = gql`
query clinics($first:Int, $last:Int, $after: String, $before:String, $where: ClinicFilterInput, $order: ClinicSort){
  clinics(first:$first, last:$last, after:$after, before:$before, where: $where, order: $order) {
    pageInfo{
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    totalCount
    edges{
      cursor
      node{
        ...clinicsAttr
        ...primaryAddressAttr
        ...clinicGroupsAttr
      }
    }
  }
}
${CLINIC_ATTRIBUTES}
${PRIMARY_ADDRESS}
${CLINIC_GROUPS}
`;

export const clinicInfo = gql`
query clinics($where: ClinicFilterInput){
  clinics(where: $where) {
      nodes{
        acceptHMO
    acceptInsurance
    acceptPPO
    active
    adminHold
    allowCheckMoneyOrder
    allowPriceOverride
    allowPriceTierOverride
    allowShortFormReporting
    billingAddress_Id
    billingEmailRecipients
    marketingEmailRecipients
    billingSystemId
    clinicBusinessName
    clinicDesc
    clinicGuid
    clinicLegacyStatus
    clinicName
    clinicTaxId
    clinicUrl
    contractId
    criticalInfoEmailRecipients
    dea222Exempt
    deaFirstName
    deaLastName
    deaNumber
    deleted
    effectiveDate
    femaleTrocars
    financingProgram
    hasProcedureCartItems
    hasShoppingCartItems
    holdOrders
    holdOrdersReason
    id
    isVIPEnrolled
    invoiceName
    invoices30Days
    invoices45Days
    isTaxExempt
    maleTrocars
    medicareProvider
    memberOfGroup
    mentorId
    mentorEligibleStartDate
    mentorEligibleEndDate
    message
    newPatientsLastMonth
    newPatientsLTD
    newPatientsYTD
    nonPelleting
    npiNumber
    nutraceuticalEnabled
    nutraceuticalPaymentTypeId
    onHoldExempt
    ordersEmailRecipients
    outstandingInvoices
    poRequired
    prescriptiveOrders
    priceTierId
    primaryAddress_Id
    phoneNumber
    locatorActive
    region
    reportingFrequencyId
    reportProcedures
    reportsEmailRecipients
    retentionId
    reviewOrders
    selfReportingProcedures
    shippingAddress_Id
    standingPO
    starterPack
    subjectToAcl
    systemDate
    terminationDate
    terminationReason
    terminationTypeCodeId
    thirdPartyBiller
    thirdPartyBilling
    totalFemaleCount
    totalFemaleYTD
    totalMale2000Count
    totalMale2000YTD
    totalMaleCount
    totalMaleYTD
    vendorId
    isTherapyHotLine
    shadowVisit
    siteVisit
    isEnableDTP
    discountPricingGroup
    leagacyStatusEffectiveDate
    holdOrders
      }
  }
}`;

export const clinicCheckDtp = gql`
query clinics($where: ClinicFilterInput){
  clinics(where: $where) {
      nodes{
        isEnableDTP
      }
  }
}`;
export const getCustomersClinics = gql`
query clinics(
  $first: Int
  $last: Int
  $after: String
  $before: String
  $where: ClinicCustomerMappingByPermissionFilterInput
  $order: [ClinicCustomerMappingByPermissionSort!]  
) {
    clinicCustomerMappingByPermission(
    first: $first
    last: $last
    after: $after
    before: $before
    where: $where
    order: $order
  ) {
    nodes{
          	clinicID
          	clinicName
          	nonPelleting
          	nutraceuticalEnabled
          	selfReportingProcedures
        }
    
  }
}
`;

export const clinicAddress = gql`
query clinics($where: ClinicFilterInput){
  clinics(where: $where) {
      nodes{
        billingSystemId
        clinicName
        ClinicAddresses{
          address{
            phoneNumber
            address1
            address2
            city
            zipPostalCode
            stateProvince{
              name
            }
            isPrimary
          }
        }
      }
  }
}`;

export const clinicCheckEnrolledInAutoPay = gql`
query clinics($where: ClinicFilterInput){
  clinics(where: $where) {
      nodes{
        enrollAutoPay
      }
  }
}`;