import { HeaderUserinfoComponent } from './header-userinfo/header-userinfo.component';
import { DialogComponent } from 'src/app/common/dialog/dialog.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { FooterComponent } from './footer/footer.component';
import { MaterialModule } from '../material.module';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialTableComponent } from './material-table/material-table.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { NavMenuSubComponent } from './nav-menu-sub/nav-menu-sub.component';
import { NavClinicSelectorComponent } from './nav-clinic-selector/nav-clinic-selector.component';
import { ProcedureCartSummaryComponent } from './procedure-cart-total/procedure-cart-summary.component';
import { ImpersonationStatusComponent } from './impersonation-status/impersonation-status.component';


@NgModule({
    declarations: [
        ToolbarComponent,
        FooterComponent,
        NavBarComponent,
        MaterialTableComponent,
        ConfirmDialogComponent,
        DialogComponent,
        NavMenuComponent,
        NavMenuSubComponent,
        NavClinicSelectorComponent,
        HeaderUserinfoComponent,
        ProcedureCartSummaryComponent,
        ImpersonationStatusComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        MaterialModule,
        TranslateModule
    ],
    providers: [],
    exports: [
        ToolbarComponent,
        FooterComponent,
        NavBarComponent,
        TranslateModule,
        MaterialTableComponent,
        ConfirmDialogComponent,
        DialogComponent,
        MaterialModule,
    ]
})
export class CommonElementModule { }
