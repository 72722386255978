<div class="loader" *ngIf="spinner.isVisible() | async">
  <div class="loader-icon-wrap">
    <div class="loader-icon">
      <img src="../../../assets/loading.svg" alt="Loading..." />
    </div>
    <div class="loader-text">
      Please wait
    </div>
  </div>
</div>

