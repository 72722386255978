import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, Injector, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonElementModule } from './common/common.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppConfig } from './app.config.service';
import { GlobalErrorHandler } from './global-error-handler';
import { ServerErrorInterceptor } from './server-error.interceptor';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink, HttpLinkModule } from 'apollo-angular-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { DefaultOptions } from 'apollo-client';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LoadingComponent } from './common/loading/loading.component';
import { ApolloInterceptor } from './graphQL-interceptor';
import { AppInjector } from './app-injector';

export function loadConfig(appConfig: AppConfig) {
  return () => {
    return appConfig.getConfigs();
  }
}


export function createApollo(httpLink: HttpLink) {
  const defaultOptions: DefaultOptions = {
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    }
  };
  return {
    link: httpLink.create({
      uri: AppConfig.configurations.apiServer.graphQlUrl,
      withCredentials: false
    }),
    cache: new InMemoryCache({
      addTypename: false
    }),
    defaultOptions: defaultOptions
  };
}

@NgModule({
  declarations: [
    AppComponent,
    LoadingComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    CommonElementModule,
    HttpClientModule,
    ApolloModule,
    HttpLinkModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    })
  ],
  exports:[
    TranslateModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: loadConfig,
      multi: true,
      deps: [AppConfig]
    },
    { provide: HTTP_INTERCEPTORS, useClass: ApolloInterceptor, multi: true },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: ServerErrorInterceptor, multi: true },
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private injector: Injector){
    AppInjector.setInjector(injector);
  }
 }

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
