import gql from 'graphql-tag';

export const menuQuery = gql`query menus($where: MenusFilterInput)
{       
    menus(where: $where)
    {
      nodes{
        menusId
        name
        json
      }     
    }
}
`;