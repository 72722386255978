<ng-container>
  <app-impersonation-status class=""></app-impersonation-status>
</ng-container>
<ng-container *ngIf="holdOrders">
  <nav class="new-navbar d-flex align-items-center justify-content-center">
    Account on hold. Past due items in cart. &nbsp; <a class="holdOrderErrorLink" [routerLink]="['/cart']">Learn more</a>
  </nav>
</ng-container>
<nav class="navbar navbar-expand-xl navbar-light bg-light">
  <div class="container-fluid">
    <button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
      (click)="toggleCollapse()"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <a class="navbar-brand" href="/home"
      ><img
        src="./../../../assets/img/biotelogo_small.png"
        alt=""
        class="linkButton bioteLogoIcon"
        [routerLink]="['/home']"
        matTooltip="Home" [ngClass]="{ navExpandedLogo: !isCollapsed }" [ngClass]="isCollapsed ? 'logoIsCollapsed' : 'navExpandedLogo'"
    /></a>

    <div
      class="navbar-collapse"
      id="navbarSupportedContent"
      [ngClass]="{ collapse: isCollapsed }"
    >
      <app-nav-menu menuID="2"></app-nav-menu>
    </div>
    <div class="navWithoutCollapse" [ngClass]="isCollapsed ? 'navIsCollapsed' : 'navExpandedIcons'">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item cartIcon col-sm-4">
          <a class="nav-link" href="/cart">
            <img
              src="./../../../assets/img/cartIcon.svg" *ngIf="!holdOrders"
              alt=""
              class="linkButton cartIconImg"
              [routerLink]="['/cart']"
              matTooltip="My Cart"
            />
            <img
              src="./../../../assets/img/cartErrorIcon.svg" *ngIf="holdOrders"
              alt=""
              class="linkButton linkButtonError"
              [routerLink]="['/cart']"
              matTooltip="My Cart"
            />
          </a>
        </li>
        <li class="nav-item dropdown col-sm-8">
          <app-nav-bar></app-nav-bar>
        </li>
      </ul>
    </div>
  </div>
</nav>
<nav class="navbar navbarBottom">
  <span class="nav-link" *ngIf="profile">Welcome, {{profile.name}}!</span>
  <ul class="navbar-nav dropdownNav ml-auto">
    <li class="nav-item dropdown">
      <app-nav-clinic-selector></app-nav-clinic-selector>
    </li>
  </ul>
</nav>
