import { Injectable } from '@angular/core';
import { ShareDataService } from '../services/share-data.service';
import { base_customer_profile, customer_profile } from './models/customer-profile';

@Injectable({
  providedIn: 'root'


})
export class CommonPermissionService {
  customerprofile: base_customer_profile = new customer_profile;
  sub: any;
  constructor(private data: ShareDataService) {}

  getUserPermission() {
    let permissionList = [];   
    if (this.customerprofile.permissions) {
      return this.customerprofile.permissions;
    }
    return permissionList;
  }

  getModule() {
    let moduleList = [];

    this.data.getCustomerProfile().subscribe(
      (data) => {
        moduleList = data.modules;
      }
    );
    return moduleList;
  }

  isPermissionGiven(permissionKey: string): boolean {
    let hasPermission = false;
    let obj;
    this.data.getCustomerProfile().subscribe(
      (data) => {
        obj = data.permissions;
        if (obj && obj.length > 0) {
          hasPermission =
            obj.filter(permission => { return permission === permissionKey }).length > 0 ? true : false;
        }

      }

    );
    return hasPermission;
  }
}
