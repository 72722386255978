import { HttpClient } from "@angular/common/http";
import { Injectable, OnInit } from "@angular/core";
import { Apollo } from "apollo-angular";
import { Observable } from "rxjs";
import { map, tap } from 'rxjs/operators';
import { createActivityLog, getActivityLogType, getCustomersClinics, orderSuggestions } from 'src/app/queries/clinics-query';
import { clinicsSelect } from "../shared/models/clinic";



@Injectable({
  providedIn: 'root'
})
export class ClinicMenuService implements OnInit {
  constructor(private apollo: Apollo, private http: HttpClient) { }
  ngOnInit(): void {

  }

  public clinicQuery(args): Observable<clinicsSelect> {
    return this.apollo.watchQuery<clinicsSelect>({
      query: getCustomersClinics,
      variables: {
        first: args.first,
        last: args.last,
        after: args.after,
        before: args.before,
        where: args.filter ? args.filter : null,
        order: args.orderBy ? args.orderBy : { clinicName: 'DESC' },
      }
    }).valueChanges.pipe(
      map(result => result.data['clinicCustomerMappingByPermission'].nodes)
    );
  }

  public getInventoryTotals(args) {
    return this.apollo.query({
      query: orderSuggestions,
      variables: {
        where: args ? args : null
      }
    }).pipe(map(({ data }) => data['orderSuggestions']));
  }

  public getActivityLogType(id, keyword) {
    return this.apollo.query({
      query: getActivityLogType,
      variables: {
        id: { eq: id },
        where: { systemKeyword: { eq: keyword } }
      }
    }).pipe(map(({ data }) => data['activityLogType'].nodes[0]));
  }

  public updateImpersonateLiaisonActivityLog(payload) {
    return this.apollo.mutate<any>({
      mutation: createActivityLog,
      variables: {
        input: payload
      }
    }).pipe(map(({ data }) => data));
  }

  getIPAddress() {
    return this.http.get("https://jsonip.com");
  }

}
