import { Component, OnInit, ViewChild, HostListener, AfterViewInit, AfterViewChecked, AfterContentInit } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, AfterViewInit {

  isToggled1 = false;
  isToggled2 = false;
  isToggled3 = false;
  isToggled4 = false;
  isToggled5 = false;
  scrHeight;
  scrWidth;
  CopyWriteYear = new Date().getFullYear();
  @ViewChild(MatAccordion) accordion: MatAccordion;

  constructor() {
  }

  ngOnInit() {  
  }

  ngAfterViewInit(): void {
    this.getScreenSize();
  }

  @HostListener('window:resize', ['$event'])
    getScreenSize() {
      setTimeout(() => {
        this.scrHeight = window.innerHeight;
          this.scrWidth = window.innerWidth;
          if(this.scrWidth <= 1024) {
            this.accordion.closeAll();
          } else {
            this.accordion.openAll();
          }
      },0);    
    }

  onToggleClicked1(){
    this.isToggled1 = !this.isToggled1;
  }

  onToggleClicked2(){
    this.isToggled2 = !this.isToggled2;
  }

  onToggleClicked3(){
    this.isToggled3 = !this.isToggled3;
  }

  onToggleClicked4(){
    this.isToggled4 = !this.isToggled4;
  }
  onToggleClicked5(){
    this.isToggled5 = !this.isToggled5;
  }


  // Scroll to top

  isShow: boolean;
  topPosToStartShowing = 100;

  @HostListener('window:scroll')
  checkScroll() {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (scrollPosition >= this.topPosToStartShowing) {
      this.isShow = true;
    } else {
      this.isShow = false;
    }
  }
  // TODO: Cross browsing
  gotoTop() {
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
  }

}
