<div>
	<div class="dialog-container">
		<div class="dialog-body" style="max-height: 700px;">
			<h6 class="mt-20">	{{message}}</h6>
		</div>
		<div class="dialog-footer pb-10">
			<div class="container-fluid">
				<div class="row ">
					<div class="col-sm-12 col-md-12 col-xs-12 col-lg-12 text-right">
						<button *ngIf="isConfirmation" mat-raised-button color="primary" (click)="onConfirmClick()"
							tabindex="1">{{confirmButtonText}}</button>
						<button mat-raised-button mat-dialog-close tabindex="-1">{{cancelButtonText}}</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>