import { Injectable } from "@angular/core";
import { AppInjector } from "src/app/app-injector";
import { ClinicMenuService } from "src/app/services/clinic-service";

@Injectable({
    providedIn: 'root',
})

export abstract class base_customer_profile {
    constructor() {
        this.clinicMenuService = AppInjector.getInjector().get(ClinicMenuService);
    }
    permissions: string[];
    modules: any[];
    clinicId: number;
    customerId: number;
    name: string;
    email: string;
    token: string;
    sub:string
    nickname:string;
    picture:string;
    updated_at:any;
    email_verified:boolean;
    clinicMenuService:any;
    
    addLog(start){
        let comment = "";
        let keyword = "ClinicPortal.Login"
            comment = 'User '+ this.customerId + ' logged in. ';

            this.clinicMenuService.getIPAddress().subscribe(res => {
                this.clinicMenuService.getActivityLogType(this.customerId, keyword).subscribe(responseData => {
                    const activityLogPayload = {activityLogTypeId: responseData.id, comment: comment, customerId: this.customerId, ipAddress: res.ip};
                    this.clinicMenuService.updateImpersonateLiaisonActivityLog(activityLogPayload).subscribe(response => {
                      if (response) {
                        
                      }
                    });
                  });
            });
        
    }
}

export class customer_profile extends base_customer_profile {
    permissions: string[];
    modules: any[];
    clinicId: number;
    customerId: number;
    name: string;
    email: string;
    token: string;

}

export class impersonated_profile extends base_customer_profile {
    permissions: string[];
    modules: any[];
    clinicId: number;
    customerId: number;
    name: string;
    email: string;
    token: string;
    adminUser: customer_profile;
    clinicMenuService:any;
    constructor(customer_profile) {
        super();
        this.adminUser = customer_profile;
        this.clinicMenuService = AppInjector.getInjector().get(ClinicMenuService);

    }

    addLog(start){
        let comment = "";
        let keyword;
        if(start){
            keyword = "ClinicPortal.Impersonation.Started";
            comment = 'Admin user '+ this.adminUser.customerId+ ' (email:' + this.adminUser.email + ')' + ' started impersonation session for user ' + this.customerId;
        }else{
            keyword = "ClinicPortal.Impersonation.Finished";
            comment = 'Admin user '+ this.adminUser.customerId+ ' (email:' + this.adminUser.email + ')' +' ended impersonation session for user ' + this.customerId;
        }
        this.clinicMenuService.getIPAddress().subscribe(res => {
        this.clinicMenuService.getActivityLogType(this.customerId, keyword).subscribe(responseData => {
            const activityLogPayload = {activityLogTypeId: responseData.id, comment: comment, customerId: this.customerId, ipAddress: res.ip};
            this.clinicMenuService.updateImpersonateLiaisonActivityLog(activityLogPayload).subscribe(response => {
              if (response) {
                
              }
            });
          });
        });
    }
}

export class profile_factory_service{
    customerProfile: any;
    constructor(adminUser){
        this.customerProfile = adminUser;
    }
    getCustomerProfile(isImpersonated: boolean){
        let profile;
        if(isImpersonated){
            profile = new impersonated_profile(this.customerProfile);
        }else{
            profile = this.customerProfile;
        }
        return profile;
    }
}