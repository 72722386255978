import { Observable } from "rxjs";
import { AppInjector } from "src/app/app-injector";
import { ClinicMenuService } from "src/app/services/clinic-service";
import { clinicsSelect } from "../models/clinic";
import { INavSelectorClinicList } from "./INavSelectorClinicList";


export class NonAdminNavSelectorClinicList implements INavSelectorClinicList {
    // constructor(public clinicMenuService: ClinicMenuService){}
    clinicList:any;
    constructor(){
        this.clinicList = AppInjector.getInjector().get(ClinicMenuService);
    }

    getClinic(customerId: number): Observable<clinicsSelect> {    
        return this.clinicList.clinicQuery({ filter: { customerID: {"eq" :Number(customerId)}, isAdmin: {"eq" : false} }, orderBy: { clinicName: 'ASC' } });
    }

    searchClinic(customerId: number , searchText: string): Observable<clinicsSelect> {      
        return this.clinicList.clinicQuery({ filter: { search_contains: {"eq" :searchText}, customerID: {"eq" :Number(customerId)}, isAdmin: {"eq" :false} },  orderBy: { clinicName: 'ASC' } });
    }
}
