import { Input, SimpleChanges } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { CommonPermissionService } from 'src/app/shared/common-permission.service';
import { menuItem } from 'src/app/shared/models/menu';
import { MenuService } from '../../services/menu-service';
import { PermissionDataService } from 'src/app/services/permission-data.service';
import { ShareDataService } from 'src/app/services/share-data.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Location } from '@angular/common';


@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss']
})
export class NavMenuComponent implements OnInit {
  @Input() menuID: number;
  public menuData: menuItem[];
  allChildModules;
  allowedChildModules: string[] = [];
  allowedParentModules: string[] = [];
  parentChildMenuModules: any[] = [];
  parentId: any;
  constructor(private menuService: MenuService, private commonPermissionService: CommonPermissionService, private permissionDataService: PermissionDataService, private shrdSvc: ShareDataService, private router: Router, private location: Location) {

  }
  Object = Object;

  ngOnInit(): void {
    this.shrdSvc.getCustomerProfile().subscribe(
      (data) => {
        this.getMenu(data);
      }
    );
    this.router.events.pipe(
    filter(event => event instanceof NavigationEnd)
  ).subscribe(() => {
    // Your function to be called on route change
    this.onRouteChange();
  });
  }

  onRouteChange(){
    let path = this.location.path();
    const pathSegments = path.split('/');
    const lastName = pathSegments[pathSegments.length - 1];
    this.parentId = this.getParentMenuId(this.parentChildMenuModules, lastName);
    if(!this.parentId){
      this.parentId = this.getMainMenuId(lastName);
    }

  }

  getParentMenuId(data: any[], childMenuName: string): string | undefined {
      for (const item of data) {
        if (item.childMenu.includes(childMenuName)) {
          return item.parentMenuId;
        }
      }
      return undefined; // If childMenuName is not found
  }

  getMainMenuId(childMenuName: string): string | undefined {
    if(this.menuData){
      for (const item of this.menuData) {
        if (item.moduleName == childMenuName) {
          return item.menuId;
        }
      }
      return undefined;
    }
}

  //get menu data
  getMenu(data) {
    this.menuService.menuQuery({ menusId: {"eq":1} }).subscribe(res => {
      if (res && res.menus) {
        this.menuData = JSON.parse(res.menus.nodes[0].json);
        this.allowedChildModules =data.modules;
        this.parentChildMenuModules = this.permissionDataService.getParentChildMenuModules(this.menuData);
        this.onRouteChange();      }
    });
  }

  removeDomain(link: string) {
    return link.substr(link.indexOf('/', 9));
  }

  hasPermission(moduleName: string) {
    const moduleNameTrimmed = moduleName.trim();
    return (this.allowedChildModules.includes(moduleNameTrimmed) || moduleNameTrimmed.length === 0) ? true : false;
  }

  hasAllowedChild(parentModuleName: string) {
    return this.allowedParentModules.includes(parentModuleName) ? true : false;
  }

}
