import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { HomeComponent } from './component/home/home.component';
import { NoPermissionComponent } from './component/no-permission/no-permission.component';
import { LoginComponent } from './login/login.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
   },
  {
    path: '',
    children: [
      {
        path: '',
        component: LoginComponent,
        outlet: 'loutlet',
      }
    ],
    pathMatch: 'full'
  },
  {
    path: 'login',
    children: [
      {
        path: '',
        component: LoginComponent,
        outlet: 'loutlet',
      }
    ]
  },

  {
    path: '',
    //data: ROUTING_DATA.COMPONENT_DATA,
    loadChildren: () => import('./component/component.module').then(m => m.ComponentModule),
    canActivateChild: [AuthGuard]
  },
  {
    path: 'no-permission',
    component: NoPermissionComponent
  },
  {
    path: '**',
    component: NoPermissionComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
