import { Injectable } from '@angular/core';
import { 
  HttpEvent, HttpRequest, HttpHandler, 
  HttpInterceptor, HttpErrorResponse, HttpResponse 
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError, tap } from 'rxjs/operators';
import { AppInsightsService } from './services/error-handling/app-insights.service';
import { AppConfig } from './app.config.service';

@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {
  private portal = AppConfig.configurations.portalUrl;

  constructor(public appInsightsService: AppInsightsService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(
      retry(1),
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse && event.ok === true) {
          this.appInsightsService.logPageView(request.url, request.urlWithParams, event);
        }
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          // refresh token
        } else if(error.status === 424){
          setTimeout(() => {
            window.location.href = this.portal.adminPortalUrl;
          }, 5000);
          return throwError(error.error.detail);
        }else {
          this.appInsightsService.logException(error);
          return throwError(error);
        }
      })
    );    
  }
}