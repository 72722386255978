import './extendDate';

const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];

export function dateTimeFormat(date?) {
    if (date) {
        const currentdate = getInputDate(date);
        const currDate = currentdate.getDate();
        const currMonth = (currentdate.getMonth() + 1);
        const currYear = currentdate.getFullYear();
        let currHours = currentdate.getHours();
        const currMin = currentdate.getMinutes();
        const currSec = currentdate.getSeconds();
        const ampm = currHours >= 12 ? 'PM' : 'AM';
        currHours = currHours % 12;
        currHours = currHours ? currHours : 12;
        const datetime = `${currMonth}/${currDate}/${currYear} ${pad(`${currHours}`)}:${pad(`${currMin}`)}:${pad(`${currSec}`)} ${ampm}`;
        return datetime;
    }
}


export function dateFormat(date?) {
    if (date) {
        const currentdate = getInputDate(date);
        const currDate = currentdate.getDate();
        const currMonth = (currentdate.getMonth() + 1);
        const currYear = currentdate.getFullYear();
        const datetime = `${currMonth}/${currDate}/${currYear}`;
        return datetime;
    }
}
function pad(n) {
    return n < 10 ? '0' + n : n;
}
export function isValidDate(value) {
    const isValid = false;
    if (value.indexOf('T') > -1) {
        return true;
    }
    return isValid;
}

export function isMagentoValidDate(value) {
    const isValid = false;
    if (value.indexOf(' ') > -1) {
        return true;
    }
    return isValid;
}
export function getDateToDisplay(date?) {
    return date ? new Date(date).toLocaleDateString() : new Date().toLocaleDateString();
}

export function getInputDate(date?): Date {
    return date ? new Date(date).getUTCLocalDate() : new Date().getUTCLocalDate();
}

export function getMangentoDate(date) {
    if (isMagentoValidDate(date)) {
        const index = date.indexOf(' ');
        const newDate = new Date(date.substring(0, index));
        return dateFormat(newDate).toString();
    }
    return '';
}

export function getNowUTC() {
    const now = new Date();
    return new Date(now.getUTCFullYear(),
        now.getUTCMonth(),
        now.getUTCDate(),
        now.getUTCHours(),
        now.getUTCMinutes(),
        now.getUTCSeconds()
    );
}

export function getIsoStringWithTimezoneOffset(date?, offsetTime?): String {
    let dt = new Date(date);
    var statTime;
    if (offsetTime) {
        statTime = new Date(dt.getTime() - (dt.getTimezoneOffset() * 60000)).toISOString().substr(0, 10) + offsetTime;
    } else {
        statTime = new Date(dt.getTime() - (dt.getTimezoneOffset() * 60000)).toISOString();
    }
    return date ? statTime : new Date().toISOString();
}

function parseDate(str) {
    const mdy = str.split('/');
    return new Date(mdy[2], mdy[0] - 1, mdy[1]);
}

export function datediff(first, second) {
    let isValid = false;
    first = parseDate(first);
    second = parseDate(second);
    // Take the difference between the dates and divide by milliseconds per day.
    // Round to nearest whole number to deal with DST.
    const diff = Math.round((first - second) / (1000 * 60 * 60 * 24));
    if (diff < 0) {
        isValid = true;
    }
    return isValid;
}


export function monthDiff(first, second) {
    let months;
    first = parseDate(first);
    second = parseDate(second);
    months = (second.getFullYear() - first.getFullYear()) * 12;
    months -= first.getMonth();
    months += second.getMonth();
    return months <= 0 ? 0 : months;

}

export function dateFormatyyyymmdd(date, incrementDay?) {
    if (incrementDay) {
        date.setDate(date.getDate() + incrementDay)
    }
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + (d.getDate()),
        year = d.getFullYear();
    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;
    return [year, month, day].join('-');
}

export function getDateRange(startDate, endDate) {
    let dates = []
    //to avoid modifying the original date
    var theDate = new Date(startDate);
    theDate.setDate(theDate.getDate() + 1);
    while (theDate < endDate) {
        dates = [...dates, new Date(theDate)];
        theDate.setDate(theDate.getDate() + 1);
    }
    dates = [...dates, endDate]
    return dates
}

export function getLabelsForWeek(date) {
    let d = new Date(date);
    let dateNum = '' + d.getDate();
    let dayNum = d.getDay();
    return `${dateNum} ${dayNames[dayNum]}`;
}

export function getLabelsForMonth(date) {
    let d = new Date(date);
    let dateNum = '' + d.getDate();
    let monthNum = d.getMonth();
    return `${monthNames[monthNum]} ${dateNum}`;
}

export function getLabelMonthYear(date) {
    let d = new Date(date);
    let monthNum = d.getMonth();
    let year = d.getFullYear();
    return `${monthNames[monthNum]} ${year}`;
}

export function getLastTwelveMonths() {
    let d = new Date();
    d.setDate(1);
    const monthYearArr = [];
    for (let i = 0; i <= 12; i++) {
        monthYearArr.push(monthNames[d.getMonth()] + ' ' + d.getFullYear());
        d.setMonth(d.getMonth() - 1);
    }

    return monthYearArr.reverse();
}


