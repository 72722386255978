import gql from 'graphql-tag';

export const CLINIC_ATTRIBUTES = `
  fragment clinicsAttr on Clinic {
    acceptHMO
    acceptInsurance
    acceptPPO
    active
    allowCheckMoneyOrder
    allowPriceOverride
    allowPriceTierOverride
    allowShortFormReporting
    billingAddress_Id
    billingEmailRecipients
    marketingEmailRecipients
    billingSystemId
    clinicBusinessName
    clinicDesc
    clinicGuid
    clinicLegacyStatus
    clinicName
    clinicTaxId
    clinicUrl
    contractId
    createdOnUtc
    criticalInfoEmailRecipients
    dea222Exempt
    deaFirstName
    deaLastName
    deaNumber
    deleted
    effectiveDate
    femaleTrocars
    financingProgram
    hasProcedureCartItems
    hasShoppingCartItems
    holdOrders
    holdOrdersReason
    id
    invoiceName
    invoices30Days
    invoices45Days
    isTaxExempt
    maleTrocars
    medicareProvider
    memberOfGroup
    mentorId
    message
    newPatientsLastMonth
    newPatientsLTD
    newPatientsYTD
    nonPelleting
    npiNumber
    nutraceuticalEnabled
    nutraceuticalPaymentTypeId
    onHoldExempt
    ordersEmailRecipients
    outstandingInvoices
    poRequired
    prescriptiveOrders
    priceTierId
    primaryAddress_Id
    phoneNumber
    locatorActive
    marketingEmailRecipients
    region
    reportingFrequencyId
    reportProcedures
    reportsEmailRecipients
    retentionId
    reviewOrders
    selfReportingProcedures
    shippingAddress_Id
    standingPO
    starterPack
    subjectToAcl
    systemDate
    terminationDate
    terminationReason
    thirdPartyBiller
    thirdPartyBilling
    totalFemaleCount
    totalFemaleYTD
    totalMale2000Count
    totalMale2000YTD
    totalMaleCount
    totalMaleYTD
    vendorId
    Retentions{
      id
      code
    }
  }
`;

export const PRIMARY_ADDRESS = `
fragment primaryAddressAttr on Clinic {
  primaryAddress {
    id
    address1
    address2
    city
    company
    phoneNumber
}
}
`;

export const CLINIC_GROUPS = `
fragment clinicGroupsAttr on Clinic {
  ClinicGroups{
    id
    clinicGroup{
      clinicGroupName
      active
    }
  }
}
`;