<mat-form-field
  appearance="fill"
  class="no-label-field"
  [class.dropdown-open]="isDropdownOpen"
>
  <input
    #clinicInput
    type="text"
    placeholder="select a clinic"
    matInput
    [matAutocomplete]="auto"
    [(ngModel)]="searchInput"
    (input)="Search(searchInput)"
    (focus)="clicked()"
  />

  <i
    class="fa fa-caret-down"
    [class.fa-caret-up]="isDropdownOpen"
    (click)="toggleDropdown()"
  ></i>

  <mat-autocomplete
    autoActiveFirstOption
    #auto="matAutocomplete"
    (opened)="openDropdown()"
    (closed)="onDropdownClosed()"
    (clicked)="clicked()" class="mat-autocomplete"
  >
    <div class="card">
      <div class="card-body">
        <p class="card-title">{{ clinicName }}</p>
        <div class="card-text-container">
          <span class="card-text" *ngIf="clinicAddress[0]?.address?.address1"
            >{{ clinicAddress[0]?.address?.address1 }},
          </span>
          <span class="card-text" *ngIf="clinicAddress[0]?.address?.address2"
            >{{ clinicAddress[0]?.address?.address2 }},
          </span>
          <span class="card-text" *ngIf="clinicAddress[0]?.address?.city"
            >{{ clinicAddress[0]?.address?.city }},
          </span>
          <span
            class="card-text"
            *ngIf="clinicAddress[0]?.address?.stateProvince?.name"
            >{{ clinicAddress[0]?.address?.stateProvince?.name }},
          </span>
          <span
            class="card-text"
            *ngIf="clinicAddress[0]?.address?.zipPostalCode"
            >{{ clinicAddress[0]?.address?.zipPostalCode }},
          </span>
          <span class="card-text" *ngIf="clinicAddress[0]?.address?.phoneNumber"
            >Phone: {{ clinicAddress[0]?.address?.phoneNumber }}</span
          >
        </div>
      </div>
    </div>

    <mat-option
      *ngFor="let clinic of menuitemlist$ | async"
      [value]="clinic.clinicName"
      (onSelectionChange)="setClinic(clinic.clinicID)"
    >
      {{ clinic.clinicName }} - {{ clinic.billingSystemId }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
