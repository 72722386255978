import { Component, 
  ComponentFactoryResolver, 
  ComponentRef, 
  Inject, 
  OnDestroy, 
  OnInit, 
  ViewChild, 
  ViewContainerRef, 
  AfterViewInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})

export class DialogComponent implements AfterViewInit, OnDestroy {

  @ViewChild('target', { read: ViewContainerRef }) viewContainerRef: ViewContainerRef;
  public title: string = '';
  componentRef: ComponentRef<any>;
  private isViewInitialized: boolean = false;
  constructor(
    private resolver: ComponentFactoryResolver,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.title = this.data.data.title;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.isViewInitialized = true;
      this.updateComponent();
    }, 2);
  }

  updateComponent() {
    if (!this.isViewInitialized) {
      return;
    }
    if (this.componentRef) {
      this.componentRef.destroy();
    }
    const factory = this.resolver.resolveComponentFactory(this.data.component);
    this.componentRef = this.viewContainerRef.createComponent(factory);
    this.componentRef.instance.formGroup = this.data.component.formGroup;
  }


  ngOnDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }
}
