import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { Auth0Service } from 'src/app/services/auth0.service';
import { ShareDataService } from 'src/app/services/share-data.service';
import { customer_profile, impersonated_profile } from 'src/app/shared/models/customer-profile';
import { AppConfig } from '../../app.config.service';

@Component({
  selector: 'app-impersonation-status',
  templateUrl: './impersonation-status.component.html',
  styleUrls: ['./impersonation-status.component.scss']
})
export class ImpersonationStatusComponent implements OnInit {
  public isImpersonated : boolean;
  public impersonationProfile : any;
  public name = '';
  sub: Subscription;
  profile: impersonated_profile;
  adminUserId:number;
  private portal = AppConfig.configurations.portalUrl;
  constructor(
    public shareDataSvc: ShareDataService,
    public auth: AuthService,
    private auth0service: Auth0Service
    ) { }

  ngOnInit(): void {
    this.auth.userProfile$.subscribe(
      (res) => {
        this.adminUserId = Number(res.sub.split('|')[1]);
      }
    );
    this.sub = this.shareDataSvc.getCustomerProfile().subscribe(
      (data) => {
        if (data) {
          if(this.shareDataSvc.isImpersonated){
            this.profile = data;
            this.getClinicAddress();
          }
        }
      }
    );
    this.shareDataSvc.impersonationStateChangeObservable$.subscribe(res => {
      if (res) {
        this.isImpersonated = true;
      }else{
        this.isImpersonated = false;
      }
    })
  }

  getClinicAddress() {
    let customerid = this.profile.customerId;
    let currentClinicId;
    this.auth0service.getUsersClinic(customerid).subscribe(
      (data) => {
        if(this.shareDataSvc.isImpersonated){
          this.name = data.name;
        }
      }
    );
  }

  stopImpersonation(){
    let currentClinicId;
    this.profile.addLog(false);
    this.auth0service.getUsersClinic(this.adminUserId).subscribe(
      (data) => {
          currentClinicId = data.user_metadata.clinicid;
          this.auth0service.clearUserMetadata(this.adminUserId, currentClinicId);
      }
    );
    
  }

}
