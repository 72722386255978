import { Component, AfterViewInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from './auth/auth.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  events: string[] = [];
  isLeftPanActive = true;
  isToolbarActive = false;

  constructor(public auth: AuthService) { }

  ngAfterViewInit() {
    this.isToolbarActive = true;
  }

  openLeftPanel(e) {
    this.isLeftPanActive = !this.isLeftPanActive;
  }

}
