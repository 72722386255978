import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { ShareDataService } from 'src/app/services/share-data.service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {
  sub: Subscription;
  profile : any;
  profilePic:any;
  isCollapsed = false;
  constructor(public auth: AuthService, public data:ShareDataService) { }

  ngOnInit() {
    this.sub = this.data.getCustomerProfile().subscribe(
      (data) => {
        if (data) {
          this.profilePic = data.picture;
          this.profile = data;
        }
      }
    );
  }
  logout() {
    this.auth.logout();
  }


}
