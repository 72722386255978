import { Injectable } from '@angular/core';
import { IMagentoPagination } from './models/pagination';

export interface IPagination {
  first: number | null;
  last: number | null;
  after: string | null;
  before: string | null;
  endCursor: string;
  startCursor: string;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  currentPageSize: number;
  startPageIndex: number;
  endPageIndex: number;
  pageSize: ['20', '50', '100'];
  totalCount: number;
  filter: any;
  orderBy: any;
}



@Injectable({
  providedIn: 'root'
})

export class PaginationService {
  public paginationArgs: IPagination;
  public magentoPaginationArgs: IMagentoPagination;
  constructor() {
    this.paginationArgs = {
      first: 20,
      last: null,
      after: null,
      before: null,
      endCursor: null,
      startCursor: null,
      hasNextPage: null,
      hasPreviousPage: null,
      currentPageSize: 20,
      startPageIndex: 1,
      endPageIndex: 20,
      pageSize: ['20', '50', '100'],
      totalCount: 0,
      filter: null,
      orderBy: null,
     
    };
    this.magentoPaginationArgs = {
      ...this.paginationArgs, ...{
        totalPage: 0,
        currentPage: 1
      }
    };
  }

  setPageInfo(pageCurrentSize: number) {
    this.paginationArgs.first = pageCurrentSize;
    this.paginationArgs.totalCount = null;
    this.paginationArgs.last = null;
    this.paginationArgs.after = null;
    this.paginationArgs.before = null;
    this.paginationArgs.startCursor = null;
    this.paginationArgs.endCursor = null;
    this.paginationArgs.hasNextPage = null;
    this.paginationArgs.hasPreviousPage = null;
    this.paginationArgs.startPageIndex = 1;
    this.paginationArgs.endPageIndex = pageCurrentSize;
    return this.paginationArgs;
  }
  private setGraphQLData(res) {
    if (res.totalCount) {
      this.paginationArgs.totalCount = res.totalCount;
      this.paginationArgs.hasPreviousPage = res.pageInfo.hasPreviousPage;
      this.paginationArgs.hasNextPage = res.pageInfo.hasNextPage;
      this.paginationArgs.endCursor = res.pageInfo.endCursor;
      this.paginationArgs.startCursor = res.pageInfo.startCursor;
      if (this.paginationArgs.currentPageSize >= this.paginationArgs.totalCount) {
        this.paginationArgs.endPageIndex = this.paginationArgs.totalCount;
      }
    } else {
      this.paginationArgs.startPageIndex = 0;
      this.paginationArgs.endPageIndex = 0;
    }
    return this.paginationArgs;
  }

  setPageResponseInfo(res, isGraphQL = 0):any{
    if (isGraphQL === 0) {
      return this.setGraphQLData(res);
    } else if (isGraphQL === 1) {
      return this.setPageAPIResponseInfo(res);
    }
  }

  private setPageAPIResponseInfo(res) {
    if (res) {
      this.magentoPaginationArgs.totalCount = res.total_count;
      if (this.magentoPaginationArgs.currentPage === 1) {
        this.magentoPaginationArgs.hasPreviousPage = false;
      } else {
        this.magentoPaginationArgs.hasPreviousPage = true;
      }
      if (this.magentoPaginationArgs.totalCount > 0) {
        const pageCount = this.magentoPaginationArgs.totalCount / this.magentoPaginationArgs.currentPageSize;
        const reminder = this.magentoPaginationArgs.totalCount % this.magentoPaginationArgs.currentPageSize;
        let roundedPageCount = Math.floor(pageCount);
        if (reminder > 0) {
          roundedPageCount = roundedPageCount + 1;
        }
        this.magentoPaginationArgs.totalPage = roundedPageCount;
        if (this.magentoPaginationArgs.totalPage === 1) {
          this.magentoPaginationArgs.hasPreviousPage = false;
          this.magentoPaginationArgs.startPageIndex = 1;
          this.magentoPaginationArgs.endPageIndex = this.magentoPaginationArgs.totalCount;
        }
      } else {
        this.magentoPaginationArgs.startPageIndex = 0;
        this.magentoPaginationArgs.endPageIndex = 0;
      }
      if (this.magentoPaginationArgs.currentPage >= this.magentoPaginationArgs.totalPage) {
        this.magentoPaginationArgs.hasNextPage = false;
      } else {
        this.magentoPaginationArgs.hasNextPage = true;
      }
      return this.magentoPaginationArgs;
    }
  }

  setOrderBy(orderByObject?) {
    if (!this.paginationArgs.orderBy) {
      if (orderByObject) {
        this.paginationArgs.orderBy = orderByObject;
      } else {
        this.paginationArgs.orderBy = { id: 'ASC' };
      }
    }
    return this.paginationArgs;
  }

}
