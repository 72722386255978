import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { GraphQLModule } from 'src/app/graphql.module';
import { menuQuery } from 'src/app/queries/menus-query';


@Injectable({
    providedIn: 'root'
  })
  export class MenuService {
  
    constructor(
      private apollo: Apollo
    ) { }
  
    // Menu    
    public menuQuery(args): Observable<any> {
        return this.apollo.query<any>({
          query: menuQuery ,
            variables: {
              where: args ? args : null,
            }
        }).pipe(map(({ data }) => data));
    }
}

    
    
    
    