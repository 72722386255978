import gql from 'graphql-tag';

export const getCustomersClinics = gql`
query clinicCustomerMappingByPermission(
  $first: Int
  $last: Int
  $after: String
  $before: String
  $where: ClinicCustomerMappingByPermissionFilterInput
  $order: [ClinicCustomerMappingByPermissionSortInput!]  
) {
    clinicCustomerMappingByPermission(
    first: $first
    last: $last
    after: $after
    before: $before
    where: $where
    order: $order
  ) {
    nodes{
          	clinicID
          	clinicName
            search
            billingSystemId
          	nonPelleting
          	nutraceuticalEnabled
          	selfReportingProcedures
        }
    
  }
}
`;

export const orderSuggestions = gql`
  query orderSuggestions($where: OrderSuggestionFilterInput) {
    orderSuggestions(where: $where) {
      nodes {
        id
        clinicId
        generic_Name
        form
        qtyOnHand
        n6WeeksAvgWeeklyUse
        wksOnHand
        noOfPelletsOnOrder
        totalProjOnHandPellets
        expiredOnHand
        adjustedOnHand
        totalAvgProjWksOnHand
        n4WeeksSupply
        suggestedOrderQty4WeeksSupply
        clinicIdNameForm
        refreshDate
      }
    }
}`;

export const getActivityLogType = gql`
  query activityLogType($where: ActivityLogTypeFilterInput) {
    activityLogType(where: $where) {
      nodes {
        id
        enabled
        name
        systemKeyword
      }
    }
}`;

export const createActivityLog = gql`
mutation createActivityLog($input: ActivityLogInput!) {
  createActivityLog(input: $input) {
    me {
      activityLogTypeId
      comment
      customerId
      ipAddress
    }
  }
} `;