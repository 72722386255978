import { Injectable } from '@angular/core';
import createAuth0Client from '@auth0/auth0-spa-js';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import { from, of, Observable, BehaviorSubject, combineLatest, throwError } from 'rxjs';
import { tap, catchError, concatMap, shareReplay, delay, take, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { AppConfig } from '../app.config.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ShareDataService } from '../services/share-data.service';
import { base_customer_profile, customer_profile, impersonated_profile, profile_factory_service } from '../shared/models/customer-profile';
import { Auth0Service } from 'src/app/services/auth0.service'
import { ClinicMenuService } from '../services/clinic-service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  zendeskEnabled = false;
  protected configSettings = AppConfig.configurations;
  private urlFromAuth: string;
  // Create an observable of Auth0 instance of client
  auth0Client$ = (from(
    createAuth0Client({
      domain: this.configSettings.aad.domain,
      client_id: this.configSettings.aad.clientId,
      redirect_uri: `${window.location.origin}`,
      audience: this.configSettings.aad.audience,
      useRefreshTokens: true
    })
  ) as Observable<Auth0Client>).pipe(
    shareReplay(1), // Every subscription receives the same shared value
    catchError(err => throwError(err))
  );
  // Define observables for SDK methods that return promises by default
  // For each Auth0 SDK method, first ensure the client instance is ready
  // concatMap: Using the client instance, call SDK method; SDK returns a promise
  // from: Convert that resulting promise into an observable
  isAuthenticated$ = this.auth0Client$.pipe(
    delay(500),
    concatMap((client: Auth0Client) => from(client.isAuthenticated())),
    tap(res => {
      this.loggedIn = res;
      if(res && !this.zendeskEnabled){
        this.loadZendeskScript();
      }
    })
  );
  handleRedirectCallback$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.handleRedirectCallback(this.urlFromAuth)))
  );
  // Create subject and public observable of user profile data
  private userProfileSubject$ = new BehaviorSubject<any>(null);
  userProfile$ = this.userProfileSubject$.asObservable();

  private tokenSubject$ = new BehaviorSubject<any>(null);
  token$ = this.tokenSubject$.asObservable();
  private portal = AppConfig.configurations.portalUrl;
  // Create a local property for login status
  loggedIn: boolean = null;
  tokenAvailable: boolean = false;
  customerprofile: base_customer_profile = new customer_profile;
  impersonatedprofile: impersonated_profile = new impersonated_profile(customer_profile);
  factoryprofile: profile_factory_service = new profile_factory_service(null);
  profile: any;

  constructor(private router: Router, private apollo: Apollo, private data: ShareDataService, private auth0Service: Auth0Service, private clinicMenuService: ClinicMenuService, private snackBar: MatSnackBar) {
    // On initial load, check authentication state with authorization server
    // Set up local auth streams if user is already authenticated
    this.localAuthSetup();
    // Handle redirect from Auth0 login
    this.handleAuthCallback();
  }

  getTokenSilently$(options?): Observable<string> {
    return this.auth0Client$.pipe(
      take(1),
      switchMap((client: Auth0Client) => from(client.getTokenSilently(options))),
      tap(token => {
        let userData;
        this.userProfile$.subscribe(
          (profile) => {
            userData = profile;
          }
        );

        const helper = new JwtHelperService();
        const decoded = helper.decodeToken(token);
        let impersonatedData;
        if (decoded['https://www.biotecloud.com/clinicId']?.impersonateduserid) {
          //impersonation active
          this.data.setImpersonation(true);
        } else {
          this.data.setImpersonation(false);
        }
        if (!this.tokenAvailable) {
          this.customerprofile.clinicId = decoded['https://www.biotecloud.com/clinicId']?.clinicid;
          this.customerprofile.customerId = parseInt(decoded.sub.substr(6));
          this.customerprofile.permissions = decoded.permissions;
          this.customerprofile.token = token;
          this.customerprofile.email = userData.email;
          this.customerprofile.nickname = userData.nickname;
          this.customerprofile.picture = userData.picture;
          this.customerprofile.updated_at = userData.updated_at;
          this.customerprofile.email_verified = userData.email_verified;
          this.customerprofile.sub = userData.sub;
          this.customerprofile.name = userData.name;

          this.factoryprofile = new profile_factory_service(this.customerprofile);
          this.profile = this.factoryprofile.getCustomerProfile(this.data.isImpersonated);
          if (this.data.isImpersonated) {
            this.auth0Service.getImpersonatedUserInfo(decoded['https://www.biotecloud.com/clinicId']?.impersonateduserid).subscribe(
              (data) => {
                impersonatedData = data;
                this.profile.customerId = parseInt(impersonatedData.user_id.substr(6));
                this.profile.clinicId = impersonatedData.user_metadata?.clinicid;
                this.profile.permissions = impersonatedData.permissions;
                this.profile.name = impersonatedData.name;
                this.profile.token = token;
                this.profile.email = data.email;
                this.profile.nickname = data.nickname;
                this.profile.picture = data.picture;
                this.profile.updated_at = data.updated_at;
                this.profile.email_verified = data.email_verified;
                this.profile.sub = data.user_id;

                this.updatePermissionsAndUserInfo();
              }
            );
          } else {
            this.profile = this.customerprofile;
            this.updatePermissionsAndUserInfo();
          }
        }
        this.tokenAvailable = true;
        this.tokenSubject$.next(token);
      })
    );
  }

  updatePermissionsAndUserInfo() {
    this.profile.addLog(true);
    this.data.setToken(this.profile.token);
    let clinicId;
    if (this.profile && this.profile.permissions) {
      this.setUserPermission(this.profile.permissions);
      clinicId = this.profile.clinicId;
      if (clinicId != null) {
        this.clinicMenuService.clinicQuery({ filter: { customerID: {"eq" :Number(this.profile.customerId)} } }).subscribe((clinic: any) => {
          if (!clinic.find(c => c.clinicID == clinicId)) {
            this.profile.clinicId = clinic[0].clinicID;
            this.auth0Service.setUserClinic(this.profile.customerId, this.profile.clinicId);
            this.data.setCustomerProfile(this.profile);

          }
        });
      } else {
        this.clinicMenuService.clinicQuery({ filter: { customerID: {"eq" :Number(this.profile.customerId)} }, first: 30, orderBy: { clinicName: 'ASC' } }).pipe().subscribe(clinic => {
          if (clinic) {
            this.profile.clinicId = clinic[0]?.clinicID;
            if (this.profile.clinicId) {
              this.auth0Service.setUserClinic(this.profile.customerId, this.profile.clinicId);
              this.data.setCustomerProfile(this.profile);
            } else {
              if (this.data.isImpersonated) {
                this.snackBar.open('Clinics not found for this impersonated user. Redirecting to Admin Portal.', 'X', { panelClass: ['error'], duration: 5000 });
                setTimeout(() => {
                  window.location.href = this.portal.adminPortalUrl;
                }, 5000);
              } else {
                this.snackBar.open('No Clinics are mapped. Please contact Customer Service.', 'X', { panelClass: ['error'], duration: 5000 });
                setTimeout(() => {
                  this.logout();
                }, 5000);
              }
            }
          }
        });
      }
      this.data.setCustomerProfile(this.profile);

    }
  }
  // When calling, options can be passed if desired
  // https://auth0.github.io/auth0-spa-js/classes/auth0client.html#getuser
  getUser$(options?): Observable<any> {
    return this.auth0Client$.pipe(
      concatMap((client: Auth0Client) => from(client.getUser(options))),
      tap(user => this.userProfileSubject$.next(user))
    );
  }

  private localAuthSetup() {
    // This should only be called on app initialization
    // Set up local authentication streams
    const checkAuth$ = this.isAuthenticated$.pipe(
      concatMap((loggedIn: boolean) => {
        if (loggedIn) {
          const token = this.getTokenSilently$();
          // If authenticated, get user and set in app
          // NOTE: you could pass options here if needed
          return this.getUser$();
        }
        // If not authenticated, return stream that emits 'false'
        return of(loggedIn);
      })
    );
    checkAuth$.subscribe();
  }

  login(redirectPath: string = '/home') {
    // A desired redirect path can be passed to login method
    // (e.g., from a route guard)
    // Ensure Auth0 client instance exists
    this.auth0Client$.subscribe((client: Auth0Client) => {
      // Call method to log in
      client.loginWithRedirect({
        redirect_uri: `${window.location.origin}`,
        appState: { target: redirectPath }
      });
    });
  }

  private handleAuthCallback() {
    // Call when app reloads after user logs in with Auth0
    const params = window.location.search;
    this.urlFromAuth = window.location.href;
    if (params.includes('code=') && params.includes('state=')) {
      let targetRoute: string; // Path to redirect to after login processsed
      const authComplete$ = this.handleRedirectCallback$.pipe(
        // Have client, now call method to handle auth callback redirect
        tap(cbRes => {
          // Get and set target redirect route from callback results
          targetRoute = cbRes.appState && cbRes.appState.target ? cbRes.appState.target : '/';
          const codeIndex = cbRes.appState.target.indexOf('?code=');
          if (codeIndex !== -1) {
            targetRoute = cbRes.appState.target.substring(0, codeIndex);
          }
        }),
        concatMap(() => {
          // Redirect callback complete; get user and login status
          return combineLatest([
            this.getTokenSilently$(),
            this.getUser$(),
            this.isAuthenticated$
          ]);
        })
      );

      authComplete$.subscribe(([user, loggedIn]) => {
        this.data.setCustomerProfile(this.customerprofile);
        // Redirect to target route after callback processing
        this.router.navigate([targetRoute]);
      });
      // Subscribe to authentication completion observable
      // Response will be an array of user and login status
    }
  }

  logout() {
    // Ensure Auth0 client instance exists
    this.auth0Client$.subscribe((client: Auth0Client) => {
      this.loggedIn = null;
      this.removeZendeskScript();
      // Call method to log out
      client.logout({
        client_id: this.configSettings.aad.clientId,
        returnTo: `${this.configSettings.apiServer.api}`
      });
    });
  }
  setUserPermission(arrayPermission: string[]) {
    const permissionData = arrayPermission;
    const moduleList = [];
    if (permissionData) {
      permissionData.forEach(element => {
        const array = element.split(':');
        if (array.length === 2) {
          moduleList.push(array[1]);
        }
      });
      const objPermission = {
        permission: permissionData,
        module: [...new Set(moduleList)]
      };
      this.profile.permissions = arrayPermission;
      this.profile.modules = objPermission.module;
    }
  }

  private loadZendeskScript() {
    this.zendeskEnabled = true;
    let script1 = document.createElement("script");
    script1.id="ze-snippet2"
    script1.type = "text/javascript";
    script1.async = true;
    script1.src='../../assets/scripts/zendesk.js';
    document.body.appendChild(script1);

    let script2 = document.createElement("script");
    script2.type = "text/javascript";
    script2.async = true;
    script2.id = "ze-snippet";
    script2.src="https://static.zdassets.com/ekr/snippet.js?key=34a6df22-dc01-438a-aceb-a8f0f7dec150";
    document.body.appendChild(script2);
    }

    private removeZendeskScript(){
      const script1 = document.getElementById('ze-snippet2');
      const script2 = document.getElementById('ze-snippet');
      script1.parentElement.removeChild(script1);
      script2.parentElement.removeChild(script2);
    }

}
