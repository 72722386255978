<div *ngIf="clinicData" class="text-right clinic-data">
<p>
  <span><strong>Logged in as: </strong>{{name}}, </span>
  <span><strong>Clinic: </strong>{{clinicData.clinicName}}, </span>
  <span *ngIf="clinicAddress[0]?.address?.address1">{{clinicAddress[0]?.address?.address1}}, </span>
  <span *ngIf="clinicAddress[0]?.address?.address2">{{clinicAddress[0]?.address?.address2}}, </span>
  <span *ngIf="clinicAddress[0]?.address?.city">{{clinicAddress[0]?.address?.city}}, </span>
  <span *ngIf="clinicAddress[0]?.address?.stateProvince?.name">{{clinicAddress[0]?.address?.stateProvince?.name}}, </span>
  <span *ngIf="clinicAddress[0]?.address?.zipPostalCode">{{clinicAddress[0]?.address?.zipPostalCode}}, </span>
  <span *ngIf="clinicAddress[0]?.address?.phoneNumber"><strong>Phone: </strong>{{clinicAddress[0]?.address?.phoneNumber}}</span>
</p>
</div>