import gql from 'graphql-tag';

export const orders = gql`
query orders ($first:Int, $last:Int,
  $after: String, $before:String, $where: OrderFilterInput, $order: [OrderSortInput!]){
  orders(first:$first, last:$last, after:$after, before:$before, where: $where, order: $order) {
    pageInfo{
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    totalCount
    edges {
      cursor
      node {
        id
        orderTotal
        orderStatusId
        paymentStatusId
        shippingStatusId
        clinicId
        storeId
        createdOnUtc
        orderSubtotalInclTax
        orderTax
        orderShippingInclTax
        shippingMethod
        paymentMethodSystemName
        clinic{
          id
          clinicName
        }
        shipment{
          trackingNumber
          deliveryDateUtc
          createdOnUtc
        }
        billingAddress{
          address1
          address2
          city
          zipPostalCode
          country{
            twoLetterIsoCode
          }
          stateProvince{
            abbreviation
          }
        }
        shippingAddress{
          address1
          address2
          city
          zipPostalCode
          phoneNumber
          country{
            twoLetterIsoCode
          }
          stateProvince{
            abbreviation
          }
        }
        orderItem{
          quantity
          unitPriceInclTax
          priceInclTax
          product{
            name
          }
        }
        Store{
            name
          }
      }
    }
  }
}
`;

export const clinicEffectiveDate = gql`
query clinics($where: ClinicFilterInput){
  clinics(where: $where) {
      nodes{
        effectiveDate
      }
  }
}`;

export const magentoStatusCode = gql`
query magentoStatusCode($where: MagentoStatusCodeFilterInput){
  magentoStatusCodes(where: $where) {
    nodes{
      id
      status
      statusCode
    }
  }
}`