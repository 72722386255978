import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IAppConfig } from 'src/assets/config/app-config-model';
import { map } from 'rxjs/operators';
import { from } from 'rxjs';
declare var window: any;
@Injectable({
    providedIn: 'root'
})
export class AppConfig {
    static configurations: IAppConfig;
    constructor(private http: HttpClient) { }
    getConfigs(): Promise<any> {
        const jsonFile = `assets/config/config.json`;
        return from(
            fetch(jsonFile).then(function (response) {
                return response.json();
            })
        ).pipe(
            map((config) => {
                //window.config = config;
                AppConfig.configurations = <IAppConfig>config;
                return config;
            })).toPromise();
    }

}