import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  CanActivate,
  Router
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { tap } from 'rxjs/operators';
import { CommonPermissionService } from '../shared/common-permission.service';
import { ShareDataService } from '../services/share-data.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  key: string;
  module: string;
  mainModule: string;
  permissionData = [];
  constructor(
    private auth: AuthService,
    private router: Router,
    private commonPermission: CommonPermissionService,
    private data: ShareDataService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean | UrlTree> | boolean {
    return this.auth.isAuthenticated$.pipe(
      tap(loggedIn => {
        if (!loggedIn) {
          this.auth.login(state.url);
        } else {
          // calling directly the logic instead of seperate isPermissionGiven(line 40) method
          this.data.getCustomerProfile().subscribe(
            (data) => {
              if (data) {
                this.getKey(next);
                this.permissionData = data.permissions;
                const hasPermission = this.permissionData.filter(
                  (permission) => {
                    return this.key === permission;
                  }
                );
                if (hasPermission && hasPermission.length > 0) {
                  return true;
                }
                else {
                  this.router.navigate(['no-permission']);
                  return false;
                }
              }
            }
          );
        }
      })
    );
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean | UrlTree> | boolean {
    return this.auth.isAuthenticated$.pipe(
      tap(loggedIn => {
        if (!loggedIn) {
          this.auth.login(state.url);
        } else {
          return true;
        }
      })
    );
  }

  getKey(route) {
    this.key = '';
    this.key = (route.data) ? route.data.permissionKey : '';
    this.module = (route.data) ? route.data.module : '';
    this.mainModule = (route.data) ? route.data.mainModule : '';
  }

  isPermissionGiven(next: ActivatedRouteSnapshot): boolean {
    return true;
  }

  hasPermissionByName(): boolean {
    this.data.getCustomerProfile().subscribe(
      (data) => {
        if (data) {
          this.permissionData = data.permissions;
          const hasPermission = this.permissionData.filter(
            (permission) => {
              return this.key === permission;
            }
          );
          if (hasPermission && hasPermission.length > 0) {
            return true;
          }
          else
            return false;
        }
      }
    );
    return false;
  }
}
