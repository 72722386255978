import { Observable, defer, BehaviorSubject } from 'rxjs';
import { finalize, map } from 'rxjs/operators';

export const prepare = <T>(callback: () => void) => {
  return (source: Observable<T>): Observable<T> => defer(() => {
    callback();
    return source;
  });
}

export const indicate = <T>(indicator: BehaviorSubject<boolean>) => {
  return (source: Observable<T>): Observable<T> => source.pipe(
    prepare(() => indicator.next(true)),
    finalize(() => indicator.next(false))
  )
}

