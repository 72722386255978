import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { AppConfig } from '../app.config.service';
import { auth0 } from '../shared/models/auth0';
import { ShareDataService } from './share-data.service';
// service that hits auth0 to get the latest
@Injectable({
  providedIn: 'root'
})
export class Auth0Service {
  private baseUrl = AppConfig.configurations.apiServer.ApiGatewayUrl;
  private portal = AppConfig.configurations.portalUrl;

  constructor(private http: HttpClient,     public shareDataSvc: ShareDataService    ) { }

  public getUsersClinic(customerID: number): Observable<auth0> {
    const getUrl = this.baseUrl + '/auth0sync/b/GetUserData?UserId=' + customerID;
    return this.http.get<auth0>(getUrl)
  }

  public setUserClinic(customerID: number, clinicID: number) {
    //prep set call
    const setUrl = this.baseUrl + '/auth0sync/b/SetUserMetaData';
    let msgBody;
      msgBody =
      `{
  "user_id": ` + customerID + `,
  "clinic_id": ` + clinicID + `,
  }`

  this.http.post<any>(setUrl, msgBody).subscribe(data => {
    if(data){
      location.href = window.location.href.substr(0, window.location.href.indexOf('/', 9));
      return data;
    }
  })

  }

  public clearUserMetadata(customerID: number, clinicID: number) {
    //prep set call
    const setUrl = this.baseUrl + '/auth0sync/b/SetUserMetaData';
    let msgBody;
    
      msgBody =
      `{
  "user_id": ` + customerID + `,
  "clinic_id": ` + clinicID + `
  }`

    this.http.post<any>(setUrl, msgBody).subscribe(data => {
      if(data){
        window.location.href = this.portal.adminPortalUrl;
      }
  })

  }

  public getImpersonatedUserInfo(customerID: number): Observable<any> {
    const getUrl = this.baseUrl + '/auth0sync/b/GetUserAndPermissionInfo?UserId=' + customerID + '&ServerName=clinic';
    return this.http.get<any>(getUrl)
  }

}