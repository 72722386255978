export const PaymentStatus = {
    10: 'Pending',
    20: 'Authorized',
    30: 'Paid',
    35: 'Partially Refunded',
    40: 'Refunded',
    50: 'Voided'
}

export const ShippingStatus = {
    10: 'Shipping Not Required',
    20: 'Not Yet Shipped',
    25: 'Partially Shipped',
    30: 'Shipped',
    40: 'Delivered'
}

export const OrderStatus = {
    5: 'Review',
    10: 'Pending',
    20: 'Processing',
    30: 'Complete',
    40: 'Cancelled'
}