<div class="login toolbar-left" *ngIf="profile">
  <button
    class="d-none d-xl-block profile-button"
    mat-button
    color="primary"
    [matMenuTriggerFor]="menu"
    [ngClass]="{ 'd-flex': !isCollapsed }"
  >
    <img src="{{ profilePic }}" alt="" class="profilePic" />
    <span class="accountText"
      >&nbsp; Account
      <mat-icon><i class="fa fa-caret-down" aria-hidden="true"></i></mat-icon
    ></span>
  </button>
  <mat-menu #menu="matMenu" class="profile-menu">
    <span mat-menu-item class="profile_name"> {{ profile.name }}</span>
    <button mat-menu-item (click)="logout()">Sign Out</button>
  </mat-menu>
</div>
