import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { getProcedureCartSummary } from '../queries/procedure-cart-summary-queries';

@Injectable({
    providedIn: 'root'
})

export class ProcedureCartSummaryService {
    constructor(private apollo: Apollo) { }

    public getProcedureCartSummary(clinicId) {
        return this.apollo.query({
            query: getProcedureCartSummary,
            variables: {
                clinicId: Number(clinicId)
            }
        }).pipe(map(({ data }) => data["procedureCartSummary"]));
    }
}