<app-loading></app-loading>
<div class="containerMaxWidth">
    <div class="row">
        <div class="col">
            <div class="white-bg">
                <ng-container *ngIf="auth.loggedIn === true">
                    <app-toolbar *ngIf="this.isToolbarActive" (leftPanel)="openLeftPanel($event)"></app-toolbar>
                </ng-container>
                <div id="main">
                    <router-outlet></router-outlet>
                </div>
                <ng-container *ngIf="auth.loggedIn === false">
                    <router-outlet name="loutlet"></router-outlet>
                </ng-container>
            </div>
        </div>
    </div>
</div>

<ng-container *ngIf="auth.loggedIn === true">
    <app-footer></app-footer>
</ng-container>