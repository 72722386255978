import { ClinicService } from 'src/app/services/graphql/services/clinic.service';
import { AfterViewChecked, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CommonPermissionService } from 'src/app/shared/common-permission.service';
import { menuItem } from 'src/app/shared/models/menu';
import { ShareDataService } from 'src/app/services/share-data.service';
import { PermissionDataService } from 'src/app/services/permission-data.service';
import { Auth0Service } from 'src/app/services/auth0.service';
import { base_customer_profile, customer_profile } from 'src/app/shared/models/customer-profile';

@Component({
  selector: 'app-nav-menu-sub',
  templateUrl: './nav-menu-sub.component.html',
  styleUrls: ['./nav-menu-sub.component.scss']
})
export class NavMenuSubComponent implements OnInit {
  @Input() menuItems: menuItem[];
  @Input() parentChildMenuModules: any[];
  @Input() menuID: number;
  @ViewChild('childMenu', { static: true }) public childMenu: any;
  allowedChildModules: string[] = [];
  allowedParentModules: string[] = [];
  isCLinicDtpEnabled = false;
  customerprofile: base_customer_profile = new customer_profile;
  sub: any;
  panelOpenState: boolean = false;
  constructor(public router: Router,
    private commonPermissionService: CommonPermissionService,
    private clinicService: ClinicService,
    private permissionDataService: PermissionDataService,
    private auth0service: Auth0Service,
    private data: ShareDataService) {
  }

  ngOnInit(): void {
    this.allowedChildModules = this.commonPermissionService.getModule();
    this.allowedParentModules = this.permissionDataService.setPermissionForMenu(this.parentChildMenuModules);
    this.sub = this.data.getCustomerProfile().subscribe(
      (data) => {
        if (data) {
          this.customerprofile = data;
          // this.sub.unsubscribe();
          this.checkClinicDtpEnabled();
        }
      }
    );
  }

  checkClinicDtpEnabled() {
    if (this.customerprofile.clinicId) {
      this.clinicService.getClinicCheckDtp(this.customerprofile.clinicId).subscribe(
        (clinic) => {
          this.isCLinicDtpEnabled = !!clinic.isEnableDTP;
        }
      );
    }
  }

  buttonClick(item){
    window.open(item.link, '_blank').focus();
  }

  removeDomain(link: string) {
    return link.substr(link.indexOf('/', 9))
  }

  hasPermission(moduleName: string) {

    if (moduleName === 'dtp-orders') {
      return this.isCLinicDtpEnabled;
    }

    const moduleNameTrimmed = moduleName.trim();
    return (this.allowedChildModules.includes(moduleNameTrimmed) || moduleNameTrimmed.length === 0) ? true : false;
  }

  hasAllowedChild(parentModuleName: string) {
    return this.allowedParentModules.includes(parentModuleName) ? true : false;
  }

}




