import { MatTabGroup } from '@angular/material/tabs';
import {
  Component, OnInit, Input, EventEmitter, Output, ViewChild,
  ElementRef, AfterViewInit, OnChanges, ViewChildren, AfterContentInit, QueryList,ChangeDetectorRef
} from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Sort } from '@angular/material/sort';
import { SelectionModel } from '@angular/cdk/collections';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { UntypedFormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatPaginator } from '@angular/material/paginator';
import { SpinnerService } from 'src/app/common/loading/loading.service';
import { MatSort } from '@angular/material/sort';
import {MatIconModule} from '@angular/material/icon';
import { OrderHistoryService } from 'src/app/services/graphql/services/order-history.service';
import { OrdersMappingService } from 'src/app/component/clinic-management/mapping/orders-mapping.service';
export interface ITableColumn {
  field: string;
  width?: number;
  columnHeader: string;
  index?: number;
  actions?: Array<any>;
  sorting?: boolean;
  cssClass?: string;
  hide?: boolean;
  booleanField?: boolean;
  imageField?:boolean;
  hasLink?: boolean;
  hasCheckbox?: boolean;
  hasDownloadLink?: boolean;
  hasButton?: boolean;
  buttonText?: string;
  isCurrency?: boolean;
  isAddress?: boolean;
  inventoryToolsWksOnHnd?:boolean;
}

@Component({
  selector: 'app-material-table',
  templateUrl: './material-table.component.html',
  styleUrls: ['./material-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class MaterialTableComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() dataSource: MatTableDataSource<any>;
  @Input() columns: ITableColumn[];
  @Input() innerColumns: ITableColumn[];   
  @Input() innerMostColumns: ITableColumn[];  
  @Input() title: string;
  @Input() referenceId: string;
  @Input() iconKeyReference: string;
  @Input() renderTemplate: string;
  @Input() showSelect: boolean;
  @Input() showOmniBar: boolean = true;
  @Input() showExport: boolean = true;
  @Input() print = false;
  @Input() selectedYear: number;
  tabData: MatTableDataSource<any>;
  @Output() editData: EventEmitter<number> = new EventEmitter<number>();
  @Output() customSort: EventEmitter<Sort> = new EventEmitter<Sort>();  
  @Output() innerCustomSort: EventEmitter<Sort> = new EventEmitter<Sort>();
  @Output() omniBarSearch: EventEmitter<object> = new EventEmitter<object>();
  @Output() actionEventEmitter: EventEmitter<object> = new EventEmitter<object>();
  @Output() innerActionEventEmitter: EventEmitter<object> = new EventEmitter<object>();
  @Output() navigationLinkEvent: EventEmitter<object> = new EventEmitter<object>();
  @Output() printEvent: EventEmitter<object> = new EventEmitter<object>();
  @Output() checkBoxEvent: EventEmitter<object> = new EventEmitter<object>();
  @Output() tabEventEmitter: EventEmitter<object> = new EventEmitter<object>();
  @Output() innerTabEventEmitter: EventEmitter<object> = new EventEmitter<object>();
  @ViewChild(MatTable, { read: ElementRef }) private matTableRef: ElementRef;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  public expandedElement:string[]=[];
  public innerExpandedElement:string[]=[];
  public displayedColumns: string[] = [];
  public selection = new SelectionModel<any>(true, []);
  public hasTemplate = false;
  public searchText: UntypedFormControl;
  private omniBarFilter = null;
  public renderClinicTab = false;
  public renderOrderDetail = false;
  colAddTab: string[] = ['firstName', 'lastName', 'address', 'email', 'phone', 'addressType'];
  colUserTab: string[] = ['fullName', 'email', 'roles', 'primaryPhysician', 'effectiveDate', 'terminationDate'];
  colNotesTab: string[] = ['changedDatetime', 'fullName', 'adminComment'];
  @Input() sharedTabData = [];
  @Input() sharedInnerMostTabData = [];
  @Input() orderTrackingObj = [];
  @ViewChildren('userTables') userTables: QueryList<MatTable<any>>;
  @ViewChildren('notesTables') notesTables: QueryList<MatTable<any>>;
  @ViewChildren('addressTables') addressTables: QueryList<MatTable<any>>;
  @ViewChildren('userPaginator') userPaginator: QueryList<MatPaginator>;
  @ViewChildren('notesPaginator') notesPaginator: QueryList<MatPaginator>;
  @ViewChild(MatTabGroup) innerTabs: MatTabGroup;
  @ViewChildren('innerTables') innerTables: QueryList<MatTable<any>>;
  @ViewChildren('innerMostTables') innerMostTables: QueryList<MatTable<any>>;
  @ViewChildren('innerSort') innerSort: QueryList<MatSort>;
  @Input() innerDisplayedColumns =[];
  @Input() innerMostDisplayedColumns =[];
  @Input() clientPagination: boolean = false;
  resultsLength = 0;
  selectedTab = new UntypedFormControl(0);
  showTrackNo = false;

  constructor(private snackBar: MatSnackBar, private spinnerService: SpinnerService,private cd: ChangeDetectorRef, private orderHistoryService: OrderHistoryService, private mapping: OrdersMappingService) {

  }

  ngOnInit() {
    this.searchText = new UntypedFormControl();
    if (this.renderTemplate) {
      this.hasTemplate = true;
      if (this.renderTemplate == 'primaryAddress') {
        this.renderClinicTab = true;
      } else if (this.renderTemplate == 'orderDetail') {
        this.renderOrderDetail = true;
      }
    }
    this.setDisplayedColumns();
  }

  ngOnChanges() {
    this.selection.clear()    
    this.setDisplayedColumns();
    this.setTabTableData();
  }

  setTabTableData() {
    if(this.innerTables){
      this.innerTables.forEach((table, index) => {
        table.dataSource = new MatTableDataSource<any>(this.sharedTabData);
      });      
    }
    if(this.innerMostTables){
      this.innerMostTables.forEach((table, index) => {
        table.dataSource = new MatTableDataSource<any>(this.sharedInnerMostTabData);
      });      
    }
  }

  onSubmit(event) {
    if (event && event.key && event.key === 'Enter') {
      this.searchData();
    }
  }

  ngAfterViewInit() {
    this.setTableResize(this.matTableRef.nativeElement.clientWidth);
    this.setTabTableData();
    if (this.clientPagination)
    {
      this.dataSource.paginator = this.paginator;
    } 
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => {
        this.selection.select(row);
      });
  }

  clearText() {
    this.searchText.reset();
    this.omniBarFilter = null;
    this.omniBarSearch.emit(this.omniBarFilter);
  }

  searchData() {
    if (this.searchText.value === '') {
      this.omniBarFilter = null;
      this.omniBarSearch.emit(this.omniBarFilter);
    } else {
      this.omniBarSearch.emit(this.searchText.value);
    }
  }

  setTableResize(tableWidth: number) {
    let totWidth = 0;
    this.columns.forEach((column) => {
      totWidth += column.width;
    });
    const scale = (tableWidth - 5) / totWidth;
    this.columns.forEach((column) => {
      column.width *= scale;
      this.setColumnWidth(column);
    });
  }

  showTemplates(column: ITableColumn) {
    const flag = (column.booleanField === true || column.field === 'action') ? true : false;
    return flag;
  }

  customSortEvent(event: Sort) {
    if (event && event.direction) {      
      this.sharedTabData=[];
      this.innerTables.forEach((table, index) => {
        table.dataSource = new MatTableDataSource<any>(this.sharedTabData);        
      });
      this.sharedInnerMostTabData=[];
      this.innerMostTables.forEach((table, index) => {
        table.dataSource = new MatTableDataSource<any>(this.sharedInnerMostTabData);
      });
      this.customSort.emit(event);
    }
  }
  
  innerCustomSortEvent(event: Sort) {
    if (event && event.direction) { 
      const key = event.active;      
      this.sharedTabData.sort(function(a, b)
      {
       var x = a[key]; var y = b[key];
       return ((x < y) ? -1 : ((x > y) ? 1 : 0));
      });
      if(event.direction.toString().toUpperCase()=="DESC")
      this.sharedTabData.reverse();
      this.innerTables.forEach((table, index) => {
        table.dataSource = new MatTableDataSource<any>(this.sharedTabData);        
      });
      this.sharedInnerMostTabData=[];
      this.innerMostTables.forEach((table, index) => {
        table.dataSource = new MatTableDataSource<any>(this.sharedInnerMostTabData);
      });
    }
  }

  innerMostCustomSortEvent(event: Sort) {
    if (event && event.direction) { 
      const key = event.active;      
      this.sharedInnerMostTabData.sort(function(a, b)
      {
       var x = a[key]; var y = b[key];
       return ((x < y) ? -1 : ((x > y) ? 1 : 0));
      });
      if(event.direction.toString().toUpperCase()=="DESC")
      this.sharedInnerMostTabData.reverse();
      this.innerMostTables.forEach((table, index) => {
        table.dataSource = new MatTableDataSource<any>(this.sharedInnerMostTabData);        
      });
    }
  }
    // exportTable() {
  //   const numSelected = this.selection.selected.length;
  //   if (numSelected > 0) {
  //     const dataToExport = this.selection.selected;
  //     const workSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dataToExport,
  //       <XLSX.Table2SheetOpts>{ sheet: 'Sheet 1' });
  //     const workBook: XLSX.WorkBook = XLSX.utils.book_new();

  //     // Adjust column width
  //     const wscols = [
  //       { wch: 50 },
  //       { wch: 50 },
  //       { wch: 30 }
  //     ];
  //     workSheet["!cols"] = wscols;
  //     XLSX.utils.book_append_sheet(workBook, workSheet, 'Sheet 1');
  //     XLSX.writeFile(workBook, `data.xlsx`);
  //   } else {
  //     this.snackBar.open('Please select records.', 'X', { panelClass: ['error'], duration: 10000 });
  //   }
  // }
  checkboxChange(event, rowData, field) {
    const rowDetails = {
      id: null,
      label: field,
      data: rowData,
      isChecked: event.checked
    };
    if (event.checked) {
      rowDetails.id = rowData.id;
    }
    this.checkBoxEvent.emit(rowDetails);
  }

  setDisplayedColumns() {
    if (this.columns) {
      this.columns = this.columns.filter((column: ITableColumn) => {
        column.hide = column.hide ? column.hide : false;
        return column.hide === false;
      });
      this.columns.forEach((column, index) => {
        column.hide = column.hide ? column.hide : false;
        if (column.hide === false) {
          column.index = index;
          this.displayedColumns[index] = column.field;
        }
      });
    }
    if (this.innerColumns) {
      this.innerColumns = this.innerColumns.filter((column: ITableColumn) => {
        column.hide = column.hide ? column.hide : false;
        return column.hide === false;
      });
      this.innerColumns.forEach((column, index) => {
        column.hide = column.hide ? column.hide : false;
        if (column.hide === false) {
          column.index = index;
          this.innerDisplayedColumns[index] = column.field;
        }
      });
    }
    if (this.innerMostColumns) {
      this.innerMostColumns = this.innerMostColumns.filter((column: ITableColumn) => {
        column.hide = column.hide ? column.hide : false;
        return column.hide === false;
      });
      this.innerMostColumns.forEach((column, index) => {
        column.hide = column.hide ? column.hide : false;
        if (column.hide === false) {
          column.index = index;
          this.innerMostDisplayedColumns[index] = column.field;
        }
      });
    }
  }

  setColumnWidth(column: any) {
    const columnEls = Array.from(document.getElementsByClassName('mat-column-' + column.field));
    if (columnEls) {
      columnEls.forEach((el: HTMLDivElement) => {
        el.style.width = column.width + 'px';
      });
    }
  }

  actionEvent(row, eventName) {
    if (row && eventName) {
      row.eventName = eventName;
      this.actionEventEmitter.emit(row);
    }
  }

  innerActionEvent(row, eventName) {
    if (row && eventName) {
      row.eventName = eventName;
      this.innerActionEventEmitter.emit(row);
    }
  }

  navigationLink(data) {
    if (data) {
      this.navigationLinkEvent.emit(data);
    }
  }

  getPrintData(option) {
    const rowData = {} as any;
    rowData.data = option.number === 'selected' ? this.selection.selected : this.dataSource.data;
    rowData.type = option.type;
    return rowData;
  }

  printSlips(option) {
    if (option.number === 'selected') {
      const numSelected = this.selection.selected.length;
      if (numSelected > 0) {
        const rowData = this.getPrintData(option);
        this.printEvent.emit(rowData);
      } else {
        this.snackBar.open('Please select records.', 'X', { panelClass: ['error'], duration: 5000 });
      }
    } else if (option.number === 'all') {
      const rowData = this.getPrintData(option);
      this.printEvent.emit(rowData);
    }
  }

  // onTabChanged(event, clinic) {
  //   const data = {} as {
  //     tabLabel: string, clinicId: number, primaryAddress_Id: number,
  //     billingAddress_Id: number, shippingAddress_Id: number
  //   };
  //   data.tabLabel = event.tab.textLabel;
  //   data.clinicId = Number(clinic.id);
  //   data.primaryAddress_Id = Number(clinic.primaryAddress_Id);
  //   data.billingAddress_Id = Number(clinic.billingAddress_Id);
  //   data.shippingAddress_Id = Number(clinic.shippingAddress_Id);
  //   this.tabEventEmitter.emit(data);
  //   this.setTabTableData();
  // }

   toggleRow(row, isexpand) {
     if (isexpand) {
       this.tabEventEmitter.emit(row);
       if(this.renderTemplate === 'orderDetail') {
        if(this.selectedYear <= 2020){
          if(this.orderTrackingObj[0] == null){
            this.orderTrackingObj[0] = {};
            this.orderTrackingObj[0].track_number = null;
            this.orderTrackingObj[0].carrier_code = 'FEDEX';
          }
          row.shipment[0]?.trackingNumber ? this.orderTrackingObj[0].track_number = row.shipment[0]?.trackingNumber : null;
          this.orderTrackingObj[0].carrier_code = 'FEDEX';

        }else{
          this.fetchMagentoTrackingDetail(row);
        }
        
       }
     }
   }
   toggleInnerRow(row, isexpand) {
    if (isexpand) {
      this.innerTabEventEmitter.emit(row);
    }
  }

  fetchMagentoTrackingDetail(row) {
    this.orderTrackingObj.length = 0;
    this.spinnerService.show();
    const shippingURL = `shipments?searchCriteria[filter_groups][0][filters][0][field]=order_id&searchCriteria[filter_groups][0][filters][0][condition_type]=eq&searchCriteria[filter_groups][0][filters][0][value]=${row.entity_id}`;
    this.orderHistoryService.getClinicsMagentoOrderDetail(shippingURL).subscribe(response => {
      this.spinnerService.hide();
      if (response && response.items && response.items.length > 0) {
        const shippmentData = response.items[0];
        if (shippmentData && shippmentData.tracks && shippmentData.tracks.length > 0) {
          this.orderTrackingObj = this.mapping.mappingOrderShippmentList(shippmentData.tracks);
        }
      }
    }, error => {
      this.spinnerService.hide();
      this.snackBar.open(error.message, 'X', { panelClass: ['error'], duration: 5000 });
    });
  }
  getStyle(pName: string): object {
    let style: object = null;
    switch (pName) {
      case "Female Regular": style = { "background-color": "#f87ae6", 'color': "#fff", 'border-radius': ".25em", 'display': "inline-block", 'padding': "1px 4px" }; break;
      case "Female Boost":
      case "Male Boost": style = { "background-color": "#f7941d", "color": "#fff", "border-radius": ".25em", 'display': "inline-block", 'padding': "1px 4px" }; break;
      case "Male Regular": style = { "background-color": "#00c0ef", "color": "#fff", "border-radius": ".25em", 'display': "inline-block", 'padding': "1px 4px" }; break;
      case "Modified Male Procedure":
      case "Advanced Male Procedure (formerly Modified Male Procedure)":
      case "New Advanced Male Procedure (formerly New Modified Male Procedure)": style = { "background-color": "#3dab49", "color": "#fff", "border-radius": ".25em" }; break; style = { "background-color": "#009933", "color": "#fff", "border-radius": ".25em", 'display': "inline-block", 'padding': "1px 4px" }; break;
      case "Male > 2000mg":
      case "T200 Large Male Insertion": style = { "background-color": "#3dab49", "color": "#fff", "border-radius": ".25em", 'display': "inline-block", 'padding': "1px 4px" }; break;
      case "T200 Male Insertion":
      case "New T100 Male Insertion":
      case "T100 Male Insertion": style = { "background-color": "#00c0ef", "color": "#fff", "border-radius": ".25em", 'display': "inline-block", 'padding': "1px 4px" }; break;
      case "T200 Female Insertion":
      case "New T100 Female Insertion":
      case "T100 Female Insertion": style = { "background-color": "#f87ae6", 'color': "#fff", 'border-radius': ".25em", 'display': "inline-block", 'padding': "1px 4px" }; break;
      default: style = null; break;
    }
    return style;
  }

  getInnerStyle(pName:string):object{
    let style:object=null;
    switch(pName)
    {
      case "CERTIFIED PROVIDER SPOUSE": style= {"background-color":"#f87ae6",'color':"#fff",'border-radius': ".25em"};break;
      case "LIAISON SPOUSE":
      case "BIOTE EMPLOYEE SPOUSE": style={"background-color":"#f39c12","color":"#fff","border-radius": ".25em"};break;
      case "LIAISON":
      case "BIOTE EMPLOYEE": style={"background-color":"#a30000","color":"#fff","border-radius": ".25em"};break;
      case "CERTIFIED PROVIDER": style={"background-color":"#00c0ef","color":"#fff","border-radius": ".25em"}; break;
      case "FULL TIME STAFF": style={"background-color":"#00a65a","color":"#fff","border-radius": ".25em"}; break;
      default : style=null; break;     
    }
    return style;
  }
   
  //fetchMagentoTrackingDetail(clinic) {
  //   this.spinnerService.show();
  //   const shippingURL = `shipments?searchCriteria[filter_groups][0][filters][0][field]=order_id&searchCriteria[filter_groups][0][filters][0][condition_type]=eq&searchCriteria[filter_groups][0][filters][0][value]=${clinic.entity_id}`;
  //   this.magentoOrderService.getClinicsMagentoOrderDetail(shippingURL).subscribe(response => {
  //     this.spinnerService.hide();
  //     if (response && response.items && response.items.length > 0) {
  //       const shippmentData = response.items[0];
  //       if (shippmentData && shippmentData.tracks && shippmentData.tracks.length > 0) {
  //         console.log(shippmentData.tracks);
  //         this.orderTrackingObj = this.mapping.mappingOrderShippmentList(shippmentData.tracks);
  //       }
  //     }
  //   }, error => {
  //     this.spinnerService.hide();
  //     this.snackBar.open(error.message, 'X', { panelClass: ['error'], duration: 5000 });
  //   });
  // }

  openWindow(url) {
    window.open(url);
  }

  navigateToCarrier(event) {
    let url = '';
    if (event) {
      switch (event.carrier_code.toUpperCase()) {
        case 'FEDEX GND':
        case 'FEDEX': {
          url = 'https://www.fedex.com/apps/fedextrack/?action=track&tracknumbers=' + event.track_number + '&locale=en_US&cntry_code=us';
          this.openWindow(url);
          break;
        }
        case 'UPS':
        case 'UNITED PARCEL SERVICE': {
          url = 'http://wwwapps.ups.com/WebTracking/track?trackNums=' + event.track_number + '&track.x=Track';
          this.openWindow(url);
          break;
        }
        case 'USPS':
        case 'UNITED STATES POSTAL SERVICE': {
          url = 'https://tools.usps.com/go/TrackConfirmAction.action?tLabels=' + event.track_number;
          this.openWindow(url);
          break;
        }
        case 'CANADA POST': {
          url = 'http://www.canadapost.ca/cpotools/apps/track/personal/findByTrackNumber?trackingNumber=' + event.track_number + '​​​​​&LOCALE=en          '
          this.openWindow(url);
          break;
        }
        default:
          break;
      }

    }
  }

}
